// Vendor Imports
import React from "react";
import MaterialTable from "@material-table/core";
import $ from "jquery";
import { CsvBuilder } from 'filefy';
import moment from 'moment';
import { Link } from "react-router-dom";
import Brightness1RoundedIcon from '@mui/icons-material/Brightness1Rounded';

// Local Imports
import { getRegisteredAccounts } from "../../../service/admin";

// Csv export function  

const exportCsv = (allColumns, allData) => {
    const columns = allColumns
        .filter(columnDef => {
            return columnDef.field && columnDef.export !== false;
        });
    
    let overAllData = [];
    allData.forEach(rd => {
            overAllData.push(rd);
            rd.subuser.forEach(dS => {
                let sub = Object.assign(dS, {
                    parentId: rd.RegUser_ID,
                });
                overAllData.push(sub);
            })
    });

    const data = overAllData.map(rowData =>
        columns.map(columnDef => rowData[columnDef.field])
    );
    
    // eslint-disable-next-line no-unused-vars
    const builder = new CsvBuilder('PA_Admin_Registered_Account_' + moment().format("YYYY-MM-DD") + '.csv')
        .setDelimeter(",")
        .setColumns(columns.map(columnDef => columnDef.title))
        .addRows(data)
        .exportFile();
}
let processing = false;

// Active account stats table listing
class RegisteredAccountsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableDetails: null,
            temp: null,
        };
        this.updateTabledata = this.updateTabledata.bind();
    }

    componentDidMount() {
        // Api call to fetch table details
        getRegisteredAccounts().then(res => this.handleResponse(res))
    }

    // To get tree structure in table 
    getModifiedData = resData => {
        let overAllData = [];
        resData.forEach(rd => {
            overAllData.push(rd);
            rd.subuser.forEach(dS => {
                let sub = Object.assign(dS, {
                    parentId: rd.RegUser_ID,
                });
                overAllData.push(sub);
            })
        })
        return overAllData;
    }

    // Handling api response
    handleResponse = res => {
        if (res.data.code === 200) {
            this.setState({
                tableDetails: this.getModifiedData(res.data.data.user),
                //tableDetails: res.data.data.user,
                temp: res.data.data.user
            });
        }
    };

    // To get the edit page route url
    getEditLink = data => {
        let userType = data.user_type === "Primary User" ? 'primary_user' : 'sub_user';
        return `/admin/edit-user-details/${userType}/${data.RegUser_ID}`
    }



    // Filter function for power users
    updateTabledata = () => {
        let levels = [];
        if ($('#power_custom').is(":checked")) {
            levels.push("Power Custom");
        }
        if ($('#power_entry').is(":checked")) {
            levels.push("Power Entry");
        }
        if ($('#power_entry_old').is(":checked")) {
            levels.push("PowerEntry");
        }
        if ($('#power_15').is(":checked")) {
            levels.push("Power 15");
        }
        if ($('#power_75').is(":checked")) {
            levels.push("Power 75");
        }
        if ($('#power_max').is(":checked")) {
            levels.push("Power Max");
        }
        if (levels.length === 0 || levels.length === 5) {
            let val = [];
            //Filter for Partially Registered User
            if ($('#partially_register_user').is(":checked")) {
                this.state.temp.forEach(t => {
                    if ( t.partial_registered ) {
                        val.push(t);
                    }
                });
                this.setState({ tableDetails: this.getModifiedData(val) });
            } else {
                this.setState({ tableDetails: this.getModifiedData(this.state.temp) });
            }
            
            return true;
        } else {
            let val = []
            this.state.temp.forEach(t => {
                if (levels.includes(t.subscription_txt)) {
                    //Filter for Partially Registered User
                    if ($('#partially_register_user').is(":checked")) {
                        if ( t.partial_registered ) {
                            val.push(t);
                        }
                    } else {
                        val.push(t)
                    }
                }

            });
            this.setState({ tableDetails: this.getModifiedData(val) })
            return true;
        }
    };

    // Filter function for power users
    filterDataTable = () => {
        if (processing) {
            clearTimeout(processing);
        }
        this.updateTabledata();
    }

    render() {
        // Table columns
        const columns = [
            {
                title: "ID",
                field: "RegUser_ID"
            },
            // { title: "Email", field: "User_Email", render : rowData => (rowData.partial_registered ? <><Brightness1RoundedIcon style={{ color: '#5faeff' }}/>{rowData.User_Email}  </>  : rowData.User_Email) },
            { title: "Email", field: "User_Email", render : rowData => (
                <td className={rowData.partial_registered ? "custom-td" : ""}>
                    {
                        rowData.partial_registered ?
                            <>
                                <Brightness1RoundedIcon style={{ color: '#5faeff' }}/>
                                <span>{rowData.User_Email}</span>
                            </>
                        :
                            rowData.User_Email
                    }
                </td>
            ) },
            { title: "Name", field: "User_Name", render: rowData => rowData.User_FirstName + " " + rowData.User_LastName },
            { title: "Subscription", field: "subscription_txt", render: rowData => rowData.subscription_txt },
            { title: "User Type", field: "user_type", hidden: true},
            { title: "Phone", field: "User_NewPhone" },
            { title: "Sub Start", field: "DateTime_SubscriptionStart", render: rowData => rowData.DateTime_SubscriptionStart_ft },
            { title: "Sub End", field: "DateTime_SubscriptionEnd", render: rowData => rowData.DateTime_SubscriptionEnd_ft },
            { title: "Registered", field: "DateTime_Registered", render: rowData => rowData.DateTime_Registered_ft },
            { title: "Is Internal",  field: "is_internal" },
            { title: "Terms",  render: rowData => rowData.terms },
            { title: "User Credits",  field: "User_Credits", render: rowData => rowData.User_Credits },
            { title: "Account Credits",  field: "Account_Credits", render: rowData => rowData.Account_Credits },
            { title: "Actions", render: rowData => <Link className="edit-btn" to={this.getEditLink(rowData)}>Edit</Link> },
        ];

        return (
            <section className="admin-table" >
                {this.state.tableDetails ?
                    <div>
                        <div className="row ml-1">
                            <div className="col-md-8 mt-8">
                                <div className="form-check-inline">
                                    <label className="form-check-label">
                                        <input type="checkbox" className="form-check-input" id="power_custom" onClick={this.filterDataTable} />Power Custom </label>
                                </div>
                                <div className="form-check-inline">
                                    <label className="form-check-label">
                                        <input type="checkbox" className="form-check-input" id="power_entry" onClick={this.filterDataTable} />Power Entry </label>
                                </div>
                                <div className="form-check-inline">
                                    <label className="form-check-label">
                                        <input type="checkbox" className="form-check-input" id="power_entry_old" onClick={this.filterDataTable} />Power Entry Old</label>
                                </div>
                                <div className="form-check-inline">
                                    <label className="form-check-label">
                                        <input type="checkbox" className="form-check-input" id="power_15" onClick={this.filterDataTable} />Power 15 </label>
                                </div>
                                <div className="form-check-inline">
                                    <label className="form-check-label">
                                        <input type="checkbox" className="form-check-input" id="power_75" onClick={this.filterDataTable} />Power 75 </label>
                                </div>
                                <div className="form-check-inline">
                                    <label className="form-check-label">
                                        <input type="checkbox" className="form-check-input" id="power_max" onClick={this.filterDataTable} />Power Max </label>
                                </div>
                                <div className="form-check-inline">
                                    <label className="form-check-label">
                                        <input type="checkbox" className="form-check-input" id="partially_register_user" onClick={this.updateTabledata} />Partially Registered User</label>
                                </div>
                            </div>
                            <div className="col-md-4 mt-4 PartiallyregisterUserColorCode">
                                <Brightness1RoundedIcon style={{ color: '#5faeff' }}/>
                                <span>Partially Registered User</span>
                            </div>
                        </div>

                        <MaterialTable
                            className="table display search-table table-responsive"
                            data={this.state.tableDetails}
                            parentChildData={(row, rows) => rows.find(a => a.RegUser_ID === row.parentId)}
                            columns={columns}
                            title=""
                            options={{
                                sorting: true,
                                actionsColumnIndex: -1,
                                paging: true,
                                pageSize: 10,
                                pageSizeOptions: [10, 20, 50],
                                emptyRowsWhenPaging: false,
                                search: true,
                                toolbar: true,
                                exportButton: { csv: true },
                                exportCsv,
                                rowStyle: rowData => {
                                    if (rowData.parentId) {
                                        return { backgroundColor: '#ced4da' };
                                    }
                                    return {};
                                }
                            }}
                            icons={{
                                Export: props => (<button type="button" className="btn btn-primary edit-btn down-btn">Download</button>)
                            }}
                            localization={{
                                toolbar: {
                                    nRowsSelected: '',
                                    searchPlaceholder: 'Search'
                                    
                                }
                            }}
                        />
                    </div>
                    : <div className="text-center">
                        <div
                            className="spinner-border text-primary mt-3"
                            style={{ width: "3rem", height: "3rem", marginLeft: "auto" }}
                            role="status"
                        >

                            <span className="sr-only">Loading...</span>
                        </div></div>
                }

            </section >

        );
    }
}

// Export
export default RegisteredAccountsComponent;
