import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import MaterialTable from '@material-table/core';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
//User Credit list component
function CreditList() {
  const SubscriptionHistory = useSelector((store) => store.client.SubscriptionHistory);
  const CreditsHistory = useSelector((store) => store.client.CreditsHistory);
  //console.log("CreditsHistory",CreditsHistory);
  const [creditData, setCreditData] = useState([]);
  const [SubsData, setSubsData] = useState(SubscriptionHistory);

  useEffect(()=>{
    setCreditData(CreditsHistory)
  },[CreditsHistory])
  //console.log("creditData",creditData);
  // function to filter by subscription based credit history
  const FilterBySh = (e) => {
    let sh_id = parseInt(e.target.value);
    if (sh_id) {
      //console.log("sh_id",sh_id)
      let allCreditData = CreditsHistory;
      //console.log("allCreditData",allCreditData)
      let filteredData = allCreditData.filter(historyItem => parseInt(historyItem.Sub_history_ID) === sh_id);
      setCreditData(filteredData);
    } else {
      setCreditData(CreditsHistory);
    }
  };

  const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
      color: '#000',
    },
    tooltip: {
      backgroundColor: '#000',
      fontSize: "1rem"
    },
  }));

  const classes = useStylesBootstrap();
  // customized table column
  const customRender = (key) => {
    if (key == "creditused") {
      return (
        <span>
          Credits <br /> Added/Used
        </span>
      );
    } else {
      return (
        <span>
          Account <br /> Credit <br /> Balance
          <sup>
            <Tooltip classes={classes} placement="top" arrow title="Balance This is the sum of the credits available to the Primary User and Sub-users AFTER this transaction was completed" >
              <i className="fa fa-info-circle" aria-hidden="true"></i>
            </Tooltip>
          </sup>
        </span>
      );
    }
  };
  // formated notes column
  const renderNotes = (row) => {
    return <span dangerouslySetInnerHTML={{ __html: row.notes }} />
  }
  // formated credit used column
  const renderCreditUsed = (row) => {
    if (row.Credits_Added_Used === "Unlimited1,000,000") {
      return 'Unlimited';
    } else {
      return row.Credits_Added_Used;
    }
  }
  // formated credit balance column
  const renderBalance = (rowData) => {
    if (rowData.Account_Credit_Balance === 'Unlimited') {
      return <NumberFormat value={parseInt(rowData.Account_Credit_Balance)} displayType={'text'} thousandSeparator={true} />
    } else {
      return rowData.Account_Credit_Balance;
    }
  }
  const localization = {
    body: {
      emptyDataSourceMessage: 'No matching records found',
    },
    toolbar: {
      searchTooltip: 'Quick Search',
      searchPlaceholder: 'Quick Search'
    }
  };
  const columns = [
    { title: "ID", field: 'th_ID', defaultSort: "desc" },
    { title: "Subsc ID", field: 'Sub_history_ID' },
    { title: "User Email", field: 'User_Email' },
    { title: "Description", field: 'notes', render: (rowData) => renderNotes(rowData) },
    { title: customRender('creditused'), customSort: (a, b) => a.credits - b.credits, field: 'Credits_Added_Used', render: (rowData) => renderCreditUsed(rowData) },
    { title: "Date", field: 'Date' },
    { title: customRender('creditbalance'), field: 'balance', render: (rowData) => renderBalance(rowData) }
  ];

  return (
    <>
      <div className="dataTables_length" id="transactionHistoryTable_length">
        <label>Filter By Subscription History  {" "}
          <select onChange={(e) => FilterBySh(e)} id="TransactionFilter">
            <option value="">All</option>
            {
              SubsData && SubsData.length > 0 ?
                SubsData.map(function (item, i) {
                  return <option key={i} value={item.sh_id}>{item.sh_id}</option>
                })
                :
                ""
            }
          </select>
        </label>
      </div>
      <MaterialTable
        className="table display search-table table-responsive"
        id="creditList"
        columns={columns}
        data={creditData}
        title=""
        localization={localization}
        options={{ sorting: true, pageSize: 5, emptyRowsWhenPaging: false }}
      />
      <br />
      <p> * The transaction tables on this page only include information from December 16, 2019 and later.</p>
    </>
  );

}

export default CreditList;
