// Vendor Imports
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Swal from 'sweetalert2'
import MaterialTable from "@material-table/core";

// Local Imports
import { unlockGovernmentList, unlockGoverment } from "../../../../service/dms";
import { dateFormatted } from "../../../../utils";


// Unlock government list
class Table extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableDetails: null,
            loading: '',
            selectedRows: [],
            columns: [
                {
                    title: "Job ID",
                    field: "job_id"
                },
                { title: "VA ID", field: "vaid" },
                { title: "Job State", field: "jstate" },
                { title: "Mode", field: "mode", render: rowData => rowData.mode.toUpperCase() },
                { title: "Government ID", field: "Government_ID" },
                { title: "Disposition", field: "disposition" },
                { title: "Government Name", field: "Government_Place_Name", render: rowData => rowData.Government_Place_Name.toUpperCase() },
                { title: "Election Month", field: "Election" },
                { title: "Last Confirmed Date", field: "last_verified_date_dt", render: rowData => dateFormatted(rowData.last_verified_date_dt) },
                { title: "Created At", field: "created_at_dt", render: rowData => dateFormatted(rowData.created_at_dt) }
            ]
        };
    }

    componentDidMount() {
        this.getDetails();
    }

    // Api call to get unlock gov list
    getDetails = () => {
        this.setState({
            tableDetails: null
        });
        unlockGovernmentList().then(res => this.handleResponse(res));
    }

    // Response Handler
    handleResponse = res => {
        if (res.data.code === 200) {
            this.setState({
                tableDetails: res.data.data
            });
        }
    };

    // Unlock gov Handler
    handleUnlock = () => {
        let params = {
            jid: this.state.selectedRows.map(s => s.job_id)
        }
        this.setState({ loading: "loading" })
        unlockGoverment(params).then(res => {
            if (res.data.code === 200) {
                this.setState({ loading: "", selectedRows: [] });
                this.getDetails();
            } else {
                this.setState({ loading: "" });
                Swal.fire({
                    text: res.data.message,
                    showCloseButton: true,
                    confirmButtonText: "Ok",
                    icon: 'error',
                    allowOutsideClick: false
                });
                return;
            }
        })
    }

    // Get button text handler
    getButtonText = val => {
        if (val.length === 0) {
            return 'Unlock Government';
        }
        if (val.length === 1) {
            return `Unlock ${val.length} Government`;
        }
        if (val.length > 1) {
            return `Unlock ${val.length} Governments`;
        }
    }

    render() {
        return (
            <div>
                {this.state.tableDetails ?
                    <div className="mb-4 mt-3">
                        <div className="mb-2 text-center">
                            {this.state.selectedRows.length === 0 ? <button type="button" className="btn btn-info" disabled style={{ cursor: "not-allowed" }}>Unlock Government</button>
                                : <button type="button" className={"btn btn-info " + this.state.loading} onClick={this.handleUnlock}>{this.getButtonText(this.state.selectedRows)}</button>}

                        </div>
                        <section className="dmstable">
                            <MaterialTable
                                className="table display search-table table-responsive"
                                columns={this.state.columns}
                                data={this.state.tableDetails}
                                title=""
                                options={{
                                    sorting: true,
                                    selection: true,
                                    actionsColumnIndex: -1,
                                    pageSize: 10,
                                    emptyRowsWhenPaging: false
                                }}
                                onSelectionChange={(rows) => this.setState({ selectedRows: rows })}
                                localization={{
                                    toolbar: {
                                        nRowsSelected: '',
                                    }
                                }}
                            />
                        </section>
                    </div> : <div className="text-center">
                        <div
                            className="spinner-border text-primary mt-3"
                            style={{ width: "3rem", height: "3rem", marginLeft: "auto" }}
                            role="status"
                        >
                            <span className="sr-only">Loading...</span>
                        </div></div>
                }

            </div>
        );
    }
}

// Export
export default withRouter(connect()(Table));
