// Vendor Imports
import React from "react";
import { Field, reduxForm } from "redux-form";
import Button from "@mui/material/Button";

// Local Imports
import validate from "./validate";

// Input Field
const renderInputField = ({
  input,
  label,
  type,
  meta: { touched, error, warning }
}) => (
  <div>
    <input
      {...input}
      placeholder={label}
      type={type}
      className="form-control"
    />
    {touched &&
      ((error && <span className="text-danger">{error}</span>) ||
        (warning && <span className="text-danger">{warning}</span>))}
  </div>
);

// ChangePassword Form
const ChangePasswordForm = props => {
  const { handleSubmit } = props;
  return (
    <form onSubmit={handleSubmit}>
      <div className="row hide-on-success">
        <div className="col">
          <div className="form-group">
            <Field
              type="password"
              label="Current Password"
              name="oldPassWord"
              id="changePasswordOld"
              component={renderInputField}
            />
          </div>
        </div>
      </div>

      <div className="row hide-on-success">
        <div className="col-sm-6">
          <div className="form-group">
            <Field
              type="password"
              label="Create New Password"
              name="newPassWord"
              id="changePasswordNew"
              component={renderInputField}
            />
          </div>
        </div>

        <div className="col-sm-6">
          <div className="form-group">
            <Field
              type="password"
              name="confirmPassWord"
              label="Confirm Password"
              id="changePasswordCon"
              component={renderInputField}
            />
          </div>
        </div>
      </div>

      <div className="row hide-on-success">
        <div className="col">
          <div className="manage-sub-btn">
            <Button
              type="submit"
              variant="container"
              className={"btn btn-pa" + props.loading}
            >
              Submit
            </Button>
            <Button
              variant="container"
              onClick={props.toggle}
              className="btn btn-outline-secondary close-modal"
            >
              Back
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};

// Redux Form
const reduxChangePasswordForm = reduxForm({
  // a unique name for the form
  form: "changePassword",
  validate
})(ChangePasswordForm);

// Export
export default reduxChangePasswordForm;
