import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Population from './population';
import GovernemntRoles from './GovernmentRoles'
import Locations from './LocationByState';
import Button from '@mui/material/Button';
import Cookies from 'js-cookie';
import { setNewUserServeyForm, setSurveyformStep, refreshUserDetails, setUserInfo, setMySavedSearches, setCurrentSearch, setSearchResult, setBestPricing, setSearchLoading, setPhoneVerificationFrom } from '../../../../reducers/client/actions';
import { getMyAccount, saveNewSearch, getSearchResult, getbestPricing, saveMyFirstSearch } from '../../../../service/client';

// my first search form component
function MyFirstSearchForm(props) {
  const [loading, setLoading] = React.useState('');
  const [RoleValidation, setRoleValidation] = React.useState('');
  const dispatch = useDispatch();
  const NewUserServeyForm = useSelector((store) => store.client.NewUserServeyForm);
  const info = useSelector((store) => store.client.info);
  const mySavedSearches = useSelector((store) => store.client.mySavedSearches);
  const myaccount = useSelector((store) => store.client.myaccount);
  let FormStep = useSelector((store) => store.client.NewUserServeyFormStep);
  let activeStep = FormStep.activeStep;
  const newSurveyResponse = useSelector((store) => store.client.info.survey_response ? JSON.parse(store.client.info.survey_response) : "");
  useEffect(() => {
    if (newSurveyResponse !== "") {
      dispatch(setNewUserServeyForm(newSurveyResponse));
      //dispatch(setSurveyformStep({activeStep: 1, skipStep:FormStep.skipStep}));
    }
  }, []);

  const handleSubmit = () => {
    setRoleValidation("");
    Cookies.remove('searchLoop');
    Cookies.remove('forcePopulationUpdate');
    Cookies.set('searchLoop', 0);
    if ((!NewUserServeyForm.position || NewUserServeyForm.position.length === 0) && activeStep === 0) {
      setRoleValidation("Government ROLE must be selected");
      return false;
    }
    if ((NewUserServeyForm.population.populationMoreThan === NewUserServeyForm.population.populationThan) && activeStep === 0) {
      setRoleValidation("Population range cannot be same");
      return false;
    }

    if ((typeof NewUserServeyForm.where === "undefined") && activeStep === 0 || NewUserServeyForm.where.length === 0) {
      setRoleValidation("State must be selected");
      return false;
    }
    setLoading('loading');
    let params = { ...NewUserServeyForm };
    saveMyFirstSearch(params).then(res =>
      handleResponse(res),
    )
  }

  const reSaveMyFirstSearch = ()=>{
    setLoading('loading');
    let searchLoop = parseInt(Cookies.get('searchLoop'));
    if(searchLoop===0 && NewUserServeyForm.size.lt===20000000){
       //NewUserServeyForm.size.lt
      NewUserServeyForm.size = { gt: 25000, lt: NewUserServeyForm.size.lt};
      NewUserServeyForm.population = { populationMoreThan: 25000, populationThan: NewUserServeyForm.size.lt };
    }else if(searchLoop===1 && NewUserServeyForm.size.lt===20000000){
      NewUserServeyForm.size = { gt: 25000, lt: 500000};
      NewUserServeyForm.population = { populationMoreThan: 25000, populationThan: 500000 };
    }
    Cookies.set('searchLoop', searchLoop+1);
    dispatch(setCurrentSearch([]));
    dispatch(setSearchResult([]));
    dispatch(setBestPricing([]));
    dispatch(setMySavedSearches([]));
    dispatch(setSearchLoading(false));
    dispatch(setNewUserServeyForm(NewUserServeyForm));
    dispatch(setSurveyformStep({activeStep: 0, skipStep:false}));
    Cookies.set('forcePopulationUpdate', true);
    let params = { ...NewUserServeyForm };
    saveMyFirstSearch(params).then(res =>
      handleResponse(res),
    )
  }

  const handleResponse = (res) => {
    if (res.data.code === 200) {
      LoadMyFirstSearch();
      dispatch(setPhoneVerificationFrom([]));
    } else {
      setLoading('');
    }

  }
  // function to load my first search after user completed survey form and phone verification
  const LoadMyFirstSearch = () => {
    let userSearch = {
      search_params: "",
      id: "",
      source: "",
      name: "",
      first_time_login: 1
    }
    dispatch(setSearchLoading(true));
    getSearchResult(userSearch).then(res => handleSearchResponse(res));
  }
  // handle my first search result
  const handleSearchResponse = (response) => {

    if (response.data.code === 200) {
      dispatch(setSearchResult(response.data.data));
      let unpaid = response.data.data.credit_required;
      let bestParams = {
        checkout_paid_records: response.data.data.checkout_paid_records,
        checkout_unpaid_records: unpaid,
      }
      if (parseInt(response.data.data.scrvr) !== 0) {
        getbestPricing(bestParams).then(bpres =>
          dispatch(setBestPricing(bpres.data.data)),
        )
      } else {
        dispatch(setBestPricing([]));
      }

      getMyAccount().then(res =>
        dispatch(refreshUserDetails(res.data.data))
      );


      let saveMyFirstSearchParams = {
        savesearch_label: "My First Download",
        table_id: response.data.data.table,
        lastSearchParams: response.data.data.search_params,
        lastSearchNumMatched: response.data.data.lastSearchNumMatched
      }
      saveNewSearch(saveMyFirstSearchParams).then(mfsres =>
        handleMyFirstSearchResponse(mfsres, response.data.data.lastSearchNumMatched),
      );
      // let userInfo = info;
      // userInfo.newUserFirstLogin = false;
      // dispatch(setUserInfo(userInfo));
    }
  }
  // save my first search result in store
  const handleMyFirstSearchResponse = (response,lastSearchNumMatched) => {
    setLoading('');
    if (response.data.code === 200) {
      if(parseInt(lastSearchNumMatched)<300){
      
        let searchdata = response.data.data;
        let mySavedSearchesList = mySavedSearches;

        mySavedSearchesList.push(searchdata);
        dispatch(setMySavedSearches(mySavedSearchesList));
        dispatch(setCurrentSearch(searchdata));
        dispatch(setSearchLoading(false));
        getMyAccount().then(res =>
          dispatch(refreshUserDetails(res.data.data))
        );
        let userInfo = info;
        userInfo.newUserFirstLogin = false;
        dispatch(setUserInfo(userInfo));
        dispatch(setSurveyformStep({activeStep: 1, skipStep:false}));
      }else if(parseInt(lastSearchNumMatched)>300){
        reSaveMyFirstSearch();
      }
    }
  }
  const handleWelcome = () => {
    props.onLoad(false);
  }

  const handleSkipSurvey = () =>{
    let params = {where:"all",
    size:{gt:0,
    lt:20000000},
    position:["role_1"],
    roles:{role_1: "Top+Elected+Official"},
    locations:null,
    population:{"populationMoreThan":0,
    populationThan:20000000}};

    setLoading('loading');
    saveMyFirstSearch(params).then(res =>
      handleSkipSurveyResponse(res),
    )
  }
  const handleSkipSurveyResponse = (res)=>{
    if (res.data.code === 200) {
      props.onLoad(false);
      dispatch(setSurveyformStep({activeStep: 1, skipStep:true}));
      getMyAccount().then(res =>
        dispatch(refreshUserDetails(res.data.data))
      );
     
    } else {
      setLoading('');
    }
  }

  return (
    <>
      {
        props.welcome === true ?
          <div className="row">
            <div className="col text-center">
              <p>Welcome {myaccount.User_FirstName} !</p>
              <p>Your trial account comes with 100 free download credits.</p>
              <Button className={"ml-2 mb-2 "} variant="contained" color="primary" onClick={() => handleWelcome()}>
                Use contact selection wizard
              </Button>
              <p className="mb-0">OR</p>
              <Button className={"ml-2 " + loading} variant="contained" color="primary" onClick={() => handleSkipSurvey()}>
              Just take me to the application
              </Button> 
             
            </div>
          </div>
          :
          <>
            <div className="row">
              <div className="col">
                <label>
                  <strong>Step 1. SELECT any ONE  government ROLE </strong>
                  <GovernemntRoles />
                </label>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col">
                <label>
                  <strong>Step 2. SELECT any ONE state </strong>
                  <Locations />
                </label>
              </div>
            </div>
            <div className="row  mt-3">
              <div className="col">
                <label>
                  <strong>Step 3. [OPTIONAL] - SELECT local government population size
                  </strong>
                  <Population />
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col text-center">
                {
                  RoleValidation !== '' ?
                    <div className="alert alert-danger">
                      {RoleValidation}
                    </div>
                    :
                    ""
                }
                <Button className={"ml-2 " + loading} variant="contained" color="primary" onClick={() => handleSubmit()}>
                  Submit
                </Button>
              </div>
            </div>
          </>
      }


    </>
  );


}

// Export
export default MyFirstSearchForm;
