// Vendor Imports
import React from "react";
import { connect } from "react-redux";
import MaterialTable from "@material-table/core";

// Local Imports
import { getAgeingMis } from "../../../../service/dms";
import { dateFormatted } from "../../../../utils";

// Ageing Mis component Table
class AgeingMisTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableDetails: null,
            columns: [
                {
                    title: "Last Confirmed Date",
                    field: "last_confirmed_date_dt",
                    render: rowData => dateFormatted(rowData.last_confirmed_date_dt)
                },
                { title: "Number of Records", field: "number_of_records" },
                { title: "Number of Records with Email", field: "number_of_records_with_email" },
                { title: "% Records with Email", field: "%_records_with_email" },
                { title: "% Old Records", field: "%_old_records" },
                { title: "< 30 Days", field: "<_30_days" },
                { title: "31 - 60 Days", field: "31-60_days" },
                { title: "61 - 90 Days", field: "61-90_days" },
                { title: "91 - 120 Days", field: "91-120_days" },
                { title: "121 - 150 Days", field: "121-150_days" },
                { title: "151 - 175 Days", field: "151-175_days" },
                { title: "176 - 203 Days", field: "176-203_days" },
                { title: "> 203 Days", field: ">_203_days" }
            ]
        };
    }

    componentDidMount() {
        getAgeingMis().then(res => this.handleResponse(res));
    }

    handleResponse = res => {
        if (res.data.code === 200) {
            this.setState({
                tableDetails: res.data.data.mis_data
            });
        }
    };


    render() {

        return (
            <div>
                {this.state.tableDetails ? <div>
                    <section className="dmstable mt-3">
                        <div className="row voicetitle">
                            <div className="col-md-12"><h2>Ageing MIS</h2></div>
                        </div>
                        {/* <div className="table-responsive">
                            <table className="table table-bordered ">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            Last Confirmed Date</th>
                                        <th scope="col">Number of Records</th>
                                        <th scope="col">Number of Records with Email </th>
                                        <th scope="col">% Records with Email</th>
                                        <th scope="col">% Old Records</th>
                                        <th scope="col">{th1}</th>
                                        <th scope="col">31 - 60 Days</th>
                                        <th scope="col">61 - 90 Days</th>
                                        <th scope="col">91 - 120 Days</th>
                                        <th scope="col">121 - 150 Days</th>
                                        <th scope="col">151 - 175 Days</th>
                                        <th scope="col"> 176 - 203 Days</th>
                                        <th scope="col">{th2}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.tableDetails.map((listValue, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{listValue.last_confirmed_date}</td>
                                                <td>{listValue.number_of_records}</td>
                                                <td>{listValue.number_of_records_with_email}</td>
                                                <td>{listValue['%_records_with_email']}</td>
                                                <td>{listValue['%_old_records']}</td>
                                                <td>{listValue['<_30_days']}</td>
                                                <td>{listValue['31-60_days']}</td>
                                                <td>{listValue['61-90_days']}</td>
                                                <td>{listValue['91-120_days']}</td>
                                                <td>{listValue['121-150_days']}</td>
                                                <td>{listValue['15-175_days']}</td>
                                                <td>{listValue['176-203_days']}</td>
                                                <td>{listValue['>_203_days']}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div> */}
                        <MaterialTable
                            className="table display search-table table-responsive"
                            columns={this.state.columns}
                            data={this.state.tableDetails}
                            title=""
                            options={{
                                sorting: true,
                                actionsColumnIndex: -1,
                                pageSize: 10,
                                emptyRowsWhenPaging: false
                            }}
                        />
                    </section>
                </div> : <div className="text-center">
                        <div
                            className="spinner-border text-primary mt-3"
                            style={{ width: "3rem", height: "3rem", marginLeft: "auto" }}
                            role="status"
                        >

                            <span className="sr-only">Loading...</span>
                        </div></div>}
            </div>
        );
    }
}

// Export
export default connect()(AgeingMisTable);
