// Vendor Imports
import React from "react";
import MaterialTable from "@material-table/core";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
// Local Imports
import EditForm from './EditForm';
// View API User Table
class ViewAPIUserComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editModal: false,
            editValues: {},
        };
    }

    // Open edit modal
    openEditModal = val => {
        this.setState({ editModal: true, editValues: val });
    }

    // Edit modal toggle
    editToggle = () => {
        this.setState({ editModal: false, editValues: {} });
    }

    render() {
        // Table Columns
        const columns = [
            { title: "ID", field: "id" },
            { title: "User Name", field: "username" },
            { title: "Email", field: "email" },
            { title: "AccessKey", field: "user_unique_key" },
            { title: "Password", field: "existing_password" },
            { title: "Start Date", field: "start_date" },
            { title: "End Date", field: "end_date" },
            { title: "Actions", render: rowData => <button type="button" className="btn btn-primary edit-btn" onClick={() => this.openEditModal(rowData)}>Edit</button> }
        ];
        return (
            <section className="admin-table" >
                {this.props.tableDetails ?
                    <div>
                        <MaterialTable
                            className="table display search-table table-responsive"
                            columns={columns}
                            data={this.props.tableDetails}
                            title=""
                            options={{
                                sorting: true,
                                actionsColumnIndex: -1,
                                paging: true,
                                pageSize: 20,
                                pageSizeOptions: [20, 50, 100],
                                emptyRowsWhenPaging: false,
                                search: true,
                                toolbar: true
                            }}
                            localization={{
                                toolbar: {
                                    nRowsSelected: '',
                                }
                            }}
                        />
                    </div>
                    : <div className="text-center">
                        <div
                            className="spinner-border text-primary mt-3"
                            style={{ width: "3rem", height: "3rem", marginLeft: "auto" }}
                            role="status"
                        >

                            <span className="sr-only">Loading...</span>
                        </div></div>
                }

                        {/* Edit subscription history */}
                        <Modal isOpen={this.state.editModal} backdrop="static" centered={true} >
                            <ModalHeader toggle={this.editToggle} className="backColor">Edit Subscription History</ModalHeader>
                            <ModalBody>
                                <EditForm toggle={this.editToggle} refreshData={this.props.refreshProps} editVal={this.state.editValues} />
                            </ModalBody>
                        </Modal >
            </section>

        );
    }
}

// Export
export default ViewAPIUserComponent;
