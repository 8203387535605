import React, { useEffect, useState } from 'react';
import { Chart } from "react-google-charts";
import MaterialTable from '@material-table/core';
import { Progress } from 'reactstrap'
import { getProcesslist } from "../../../service/client";
import { renderCount, getAppliedPopulation, renderEmailPercent } from "../../../utils";
function Processlist(){
    const [ProcesslistData, setProcesslistData] = useState([]);
    const [UpdatedData, setUpdatedData] = useState([]);
    const [countData, setCountData] = useState([]);
    const [chartData, SetchartData] = useState([
        ["Task", "Hours per Day"],
        ["Updated",0],
        ["Pending", 0]
      ]);
    useEffect(()=>{
            getProcesslist().then(res =>handleResponse(res)
        );
    },[]);
    const handleResponse  = (res)=>{
        setCountData(res.data.data.migrationsStats);
        SetchartData( [
            ["Task", "Hours per Day"],
            ["Updated", res.data.data.migrationsStats.Updated],
            ["Pending", res.data.data.migrationsStats.Pending]
          ]);
          setProcesslistData(res.data.data.processlist);
          setUpdatedData(res.data.data.migrationList);
    }
    const options = {
        title: "Weekly Migration Status",
        pieHole: 0.4,
        is3D: false,
    };
    
    const columns = [
        { title: "ID", field: 'ID' },
        { title: "COMMAND", field: 'COMMAND' },
        { title: "STATE", field: 'STATE' },
        { title: "INFO", field: 'INFO' },
    ];
    const updatedDownloads = [ 
        { title: "SavedDownloads ID", field: 'SavedDownloads_ID' },
        { title: "Download NumRecords", field: 'Download_NumRecords' },
        { title: "Updated Records", field: 'updated' },
        { title: "Last Checked Date", field: 'last_checked_date' },
        { title: "System Date", field: 'now()' },
        { title: "Version", field: 'version2' }
       
    ];

    const TimeTaken = ( start,end)=>{
       
        let endTime = new Date(end);
        let startTime = new Date(start);
        var msec = Math.abs( endTime - startTime )
        var min = Math.floor((msec/1000)/60/60);
        return min+" Hours";
      
    }
    return(
        <div className='container-fluid'>
            <div className='row'>
                    <div className='col-sm-4'>
                    
                        <Chart
                        chartType="PieChart"
                        width="100%"
                        height="400px"
                        data={chartData}
                        options={options}
                        />
                     
                    </div>
                    <div className='col-sm-2 m-auto'>
                        { UpdatedData.length>0 ?
                        <> 
                            <p> Started At : { UpdatedData[UpdatedData.length-1]['last_checked_date'] }</p>
                            <p> Last Run At : { UpdatedData[0]['last_checked_date'] }</p>
                            <p> Run Time :  {TimeTaken(UpdatedData[UpdatedData.length-1]['last_checked_date'] ,UpdatedData[0]['last_checked_date'] )} </p>
                           
                        </>
                        :
                        ""
                        }
                        <Progress max={countData.Updated+countData.Pending} color="info" value={countData.Updated}>
                            {renderCount(countData.Updated)}
                        </Progress>
                        <br/>
                        <Progress max={countData.Updated+countData.Pending} color="danger" value={countData.Pending}>
                            {renderCount(countData.Pending)}
                        </Progress>
                    </div>
                    <div className='col-sm-6'>
                         
                        <MaterialTable
                            className="table display search-table table-responsive"
                            id="BillingTable"
                            columns={updatedDownloads}
                            data={UpdatedData}
                            title="Updated Files"
                            options={{ sorting: true, pageSize: 5, emptyRowsWhenPaging: false }}
                            />
                     
                    </div>
                </div>
                    <div className='row'> 
                    <div className='col-sm-12 mt-4'>
                        <MaterialTable
                            className="table display search-table table-responsive"
                            id="BillingTable"
                            columns={columns}
                            data={ProcesslistData}
                            title="Update Process"
                            options={{ sorting: true, pageSize: 20, emptyRowsWhenPaging: false }}
                            />
                    </div>
            </div>
        </div>
    )
    
}

export default Processlist;