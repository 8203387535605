import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import MaterialTable from "@material-table/core";
import AddSubUserModal from "./AddUserModal.jsx";
import ManageSubUserModal from "./ManageSubUserModal.jsx";
import Button from "@mui/material/Button";
import NumberFormat from "react-number-format";
import { refreshUserDetails } from "../../../reducers/client/actions";
import { deleteSubUser } from "../../../service/client";
import { timestampToDate } from "../../../utils";
import FooterModal from '../../../layouts/GuestLayout/FooterModal';

// sub user list table component
function SubUserList(props) {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [editSubUser, setSubUser] = useState('');
  const [manageModal, setManageModal] = useState(false);

  const toggle = () => setModal(!modal);
  //fuunction open sub user modal to edit
  const manageSubUserToggle = user => {
    if (user === false) {
      setSubUser('');
      setManageModal(false);
    } else {
      setSubUser(user);
      setManageModal(!manageModal);
    }
  };

  const [cmodal, setCModal] = useState(false);
  const [cmodalContent, SetCModalContent] = useState('');
  const ctoggle = (content) => {
    setCModal(!cmodal);
    SetCModalContent(content);
  }
  // function to delete a subuser 
  const deleteSubUserByID = (UserEmail, SubuserList) => {
    let param = { e: UserEmail };

    deleteSubUser(param).then(res => dispatch(refreshUserDetails(res.data.data)));
  };

  const restrictedDomains = ["gmail.com", "yahoo.com", "aim.com", "aol.com"];
  const parentEmailDomain = props.myaccount.User_Email.split("@")[1];
  const canAdduser = !restrictedDomains.includes(parentEmailDomain)
    ? true
    : false;
  const editable = {
    isEditable: rowData => true,
    isEditHidden: rowData => false,
    isDeletable: rowData => true,
    isDeleteHidden: rowData => false,
    onRowDelete: oldData =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          const dataDelete = props.subusers;
          const index = oldData.tableData.RegUser_ID;
          dataDelete.splice(index, 1);
          deleteSubUserByID(oldData.User_Email, dataDelete);
          // getMyAccount().then(res =>
          //   dispatch(refreshUserDetails(res.data.data))
          // );
          resolve();
        }, 1000);
      })
  };

  // subuser table column definition
  const columns = [
    {
      title: "User Name",
      field: "User_FirstName",
      render: rowData => rowData.User_FirstName + " " + rowData.User_LastName
    },
    { title: "Email Address", field: "User_Email" },
    {
      title: "Credit Allocation",
      field: "User_DL_Reserves",
      render: rowData =>
        rowData.User_Subscription === "10" ? (
          "Unlimited"
        ) : (
          <NumberFormat
            value={rowData.User_DL_Reserves}
            displayType={"text"}
            thousandSeparator={true}
          />
        )
    },
    {
      title: "Date Added",
      field: "DateTime_Registered",
      customSort: (a, b) => a.DateTime_Registered < b.DateTime_Registered,
      render: rowData => timestampToDate(rowData.DateTime_Registered)
    }
  ];
  let addUserBtnClass = canAdduser ? "" : "btn-secondary";
  const localization = {
    body: {
      emptyDataSourceMessage: 'No matching records found',
      deleteTooltip: 'Delete',
      editTooltip: 'Editer',
      filterRow: {
        filterTooltip: 'Filtrer'
      },
      editRow: {
        deleteText: 'Are you sure you want to delete this sub user?',
        cancelTooltip: 'Cancel',
        saveTooltip: 'Save'
      }
    },
    header: {
      actions: 'Actions'
    },
    toolbar: {
      searchTooltip: 'Quick Search',
      searchPlaceholder: 'Quick Search'
    }
  };
  return (
    <>
      <div className="row mt-5">
        <div className="col">
          <Button
            onClick={toggle}
            className={addUserBtnClass + "  btn btn-pa float-right open-subuser-modal sub-btn"}
          >
            Add Sub-User
          </Button>
          <h2 className="display-5 text-uppercase sub-user">
            Subscription Sub-Users
          </h2>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col">
          {!canAdduser ? (
            <p
              className="float-right sub-btn"
              style={{ fontSize: "15px", fontStyle: " italic" }}
            >
              Your account email must use your organization’s email domain in
              order to add sub-users.{"   "}
              <Link onClick={() => ctoggle('Contact Us')} >Contact Us</Link>{" "}
              for more information.
            </p>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="row">
        <div className="col">
          <MaterialTable
            className="table display search-table table-responsive"
            columns={columns}
            data={props.subusers}
            title=""
            editable={editable}
            localization={localization}
            actions={[
              {
                icon: "edit",
                tooltip: "Edit User",
                onClick: (event, rowData) => manageSubUserToggle(rowData)
              }
            ]}
            options={{
              sorting: true,
              pageSize: 5,
              actionsColumnIndex: -1,
              emptyRowsWhenPaging: false
            }}
          />
        </div>
      </div>
      {canAdduser ? (
        <AddSubUserModal data={props.myaccount} modal={modal} toggle={toggle} />
      ) : (
        ""
      )}
      { editSubUser !== "" ?
        <ManageSubUserModal
          myaccount={props.myaccount}
          data={editSubUser}
          modal={manageModal}
          toggle={manageSubUserToggle}
        />
        :
        ""
      }
      {cmodal ?
        <FooterModal modal={cmodal} content={cmodalContent} toggle={ctoggle} />
        :
        ""
      }


    </>
  );
}
function mapStateToProps(store, props) {
  return {
    myaccount: store.client.myaccount ? store.client.myaccount : [],
    plan: store.client.plan ? store.client.plan : [],
    info: store.client.info ? store.client.info : [],
    subusers: store.client.subusers ? store.client.subusers : [],
  };
}

export default connect(mapStateToProps, {})(SubUserList);
