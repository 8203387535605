import React, { useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { connect, useSelector, useDispatch } from 'react-redux';
import { setAppliedFilters,setValidateFilters } from '../../../../reducers/client/actions';
import { loadSavedSearchName } from '../../../../utils';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';

// Government Role component
function GovernmentRoleFilter(props) {
  const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
      color: '#000',
    },
    tooltip: {
      backgroundColor: '#000',
      fontSize: "1rem"
    },
  }));
  const classes = useStylesBootstrap();
  let rolesFilter = useSelector(store => store.client.validFilters);


  // government role filter name and values
  const formFields = [
    { label: "Head of Corrections<span class='new-text-small text-small'> NEW!</span>", name: "role_22", value: "HeadofCorrections", checked: true },
    { label: "Geographic Information System", name: "role_21", value: "HeadofGeographicInformationSystem", checked: true },
    { label: "Health Services ", name: "role_20", value: "HeadofHealthServices", checked: true },
    { label: "Facilities Management", name: "role_19", value: "HeadofFacilitiesManagement", checked: true },
    { label: "Economic Development", name: "role_17", value: "EconomicDevelopment", checked: true },
    { label: "Planning/Zoning ", name: "role_16", value: "Zoning", checked: true },
    { label: "Parks and Recreation  ", name: "role_15", value: "ParksandRecreation", checked: true },
    { label: "Water Supply ", name: "role_14", value: "Head+of+Water+Supply", checked: true },
    { label: "IT", name: "role_10", value: "Head+of+IT", checked: true },
    { label: "Finance", name: "role_4", value: "Head+of+Finance/Budgeting", checked: true },
    { label: "Purchasing", name: "role_5", value: "Head+of+Purchasing/Procurement", checked: true },
    { label: "Clerk's Office", name: "role_9", value: "Clerk", checked: true },
    { label: "Public Works", name: "role_6", value: "Head+of+Public+Works", checked: true },
    { label: "Law Enforcement", name: "role_7", value: "Head+of+Law+Enforcement", checked: true },
    { label: "Fire", name: "role_8", value: "Head+of+Fire+Protection+Services", checked: true },
    { label: "Buildings", name: "role_11", value: "Head+Building+Official", checked: true },
    { label: "Communications", name: "role_12", value: "Head+of+Communications", checked: true },
    { label: "HR", name: "role_13", value: "Head+of+HR", checked: true },
    { label: "Top Elected Official  <br> <span class='text-small'> (e.g. the Mayor)</span>", name: "role_1", value: "Top+Elected+Official", checked: true },
    { label: "Governing Board Members <br> <span class='text-small'>(e.g. the Council Members)</span>", name: "role_3", value: "Governing+Board+Member", checked: true },
    { label: "Top Appointed Executive <br/><span class='text-small'>(e.g. the City Manager)</span>", name: "role_2", value: "Top+Appointed+Official", checked: true },
    { label: "Deputy Top Appointed Executive <br/><span class='text-small'>(e.g. the Assistant City Manager)</span>", name: "role_18", value: "DeputyTopAppointedExecutive", checked: true },
  ];
  
  const [expanded, setExpanded] = React.useState('govRoles');
  const [CheckedCount, setCheckedCount] = React.useState(formFields.length);
  const dispatch = useDispatch();

  let [Fields, setCheckedFields] = React.useState(formFields);
  let [PreviousParams, setPreviousParams] = React.useState({});
  // set initial checked inputs or loaded saved search parameters
  useEffect(() => {
    if (Object.keys(props.currentSearch).length !== 0 && PreviousParams !== props.currentSearch) {
      let loadParams = "";
      let loadSavedSearch = [];
      if (props.currentSearch.id !== "0" || props.currentSearch.id !== "") {
        loadParams = props.currentSearch.search_params;
        loadSavedSearch = loadSavedSearchName(loadParams, formFields);
      } else if (props.currentSearch.id === "0" || props.currentSearch.id === "") {
        loadParams = props.currentSearch.params;

        loadSavedSearch = loadSavedSearchName(loadParams, formFields);
      }

      if (Fields !== loadSavedSearch && loadSavedSearch.length !== 0) {

        setPreviousParams(props.currentSearch);
        setCheckedFields(loadSavedSearch.appliedFilters);
        setCheckedCount(loadSavedSearch.checkedcount);
        let appliedFilters = props.appliedFilters;
        appliedFilters.governmentRoles = loadSavedSearch.checkedcount;
        dispatch(setAppliedFilters(appliedFilters));
        if (loadSavedSearch.checkedcount !== formFields.length) {
          setExpanded("govRoles");
        } else {
          setExpanded("govRoles");
        }
      }
    }
  });
  // capture changed filter values and trigger search api
  const handleChange = (event) => {
    let MinimumChecked = 0;
    let uncheckedInputs = [];
    Fields.map((field, index) => {
      if (event.target.name === field.name) {
        field.checked = event.target.checked;
      }
      if (field.checked === true) {
        MinimumChecked++;
      }
      uncheckedInputs.push(field);
    });
    let allSearchFilter ={};
    if(MinimumChecked===0)
    {
      allSearchFilter = {
        rolesf: false,
        statesf: rolesFilter.statesf,
        gtypef: rolesFilter.gtypef,
        gftef: rolesFilter.gftef,
        gspendf: rolesFilter.gspendf,
        fydf: rolesFilter.fydf,
        molf: rolesFilter.molf
      }
      
      dispatch(setValidateFilters(allSearchFilter));
    } else {
      allSearchFilter = {
        rolesf: true,
        statesf: rolesFilter.statesf,
        gtypef: rolesFilter.gtypef,
        gftef: rolesFilter.gftef,
        gspendf: rolesFilter.gspendf,
        fydf: rolesFilter.fydf,
        molf: rolesFilter.molf
      }
      dispatch(setValidateFilters(allSearchFilter));
    }
    setCheckedFields(uncheckedInputs);
    setCheckedCount(MinimumChecked);
    let appliedFilters = props.appliedFilters;
    appliedFilters.governmentRoles = MinimumChecked;
    dispatch(setAppliedFilters(appliedFilters));
    props.triggerSearch(uncheckedInputs, true, allSearchFilter);
    
    
  };
  const handleChangeAccordian = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  // function to select all role checkboxes
  const handleSelectAll = () => {
    let uncheckedInputs = [];
    Fields.map((field, index) => {
      field.checked = true;
      uncheckedInputs.push(field);
    })
    let  allSearchFilterdt = {
        rolesf: true,
        statesf: rolesFilter.statesf,
        gtypef: rolesFilter.gtypef,
        gftef: rolesFilter.gftef,
        gspendf: rolesFilter.gspendf,
        fydf: rolesFilter.fydf,
        molf: rolesFilter.molf
      }
    dispatch(setValidateFilters(allSearchFilterdt));
    setCheckedFields(uncheckedInputs);
    setCheckedCount(Fields.length);
    let appliedFilters = props.appliedFilters;
    appliedFilters.governmentRoles = 13;
    dispatch(setAppliedFilters(appliedFilters));
    props.triggerSearch(uncheckedInputs, true,allSearchFilterdt);
  };
  // function to deselect all role checkboxes
  const handleDeSelectAll = () => {
    let uncheckedInputs = [];
    Fields.map((field, index) => {
      field.checked = false;
      uncheckedInputs.push(field);
    })
    let  allSearchFilterdf = {
        rolesf: false,
        statesf: rolesFilter.statesf,
        gtypef: rolesFilter.gtypef,
        gftef: rolesFilter.gftef,
        gspendf: rolesFilter.gspendf,
        fydf: rolesFilter.fydf,
        molf: rolesFilter.molf
      }
    dispatch(setValidateFilters(allSearchFilterdf));
    setCheckedFields(uncheckedInputs);
    setCheckedCount(0);
    let appliedFilters = props.appliedFilters;
    appliedFilters.governmentRoles = 0;
    dispatch(setAppliedFilters(appliedFilters));
  };

  

  return (
    <div id="governmentRoles" className="row governmentRoles">
      <div className="col">
        <Accordion className="accordianLine" expanded={expanded === 'govRoles'} onChange={handleChangeAccordian('govRoles')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="govRolesa-content"
            id="govRolesa-header"
          >
            <Typography className=""> Government Roles</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="row roleCheckboxes">

              <div className="col-md-6 col-12 pr-0 pl-2">
                <label className="form-check-label  roleTitle head-of-label">HEAD/CHIEF OF:</label>
                {
                  Fields.map((field, index) => {
                    //let disabled = props.plan.Sub_Level !== "10" && field.name === 'role_22' ? true : false;
                    //if (disabled) {
                      //field.checked = false;
                      //field.label = "Head of Corrections <sup> <i class='fa fa-info-circle' > </i> </sup>  <br> <span class='new-text-small text-small'>Beta for Power Max Users Only                      </span>  ";
                    //}  
                   
                    if (index < 18) {
                      return (
                        <>
                          {/*{
                            props.plan.Sub_Level !== "10" && field.name === 'role_22' ?

                              <Tooltip classes={classes} placement="top" arrow title="Power Max users have Beta Access to this new role. All other users will have access beginning on May 02." >
                                <FormGroup  key={index}>
                                  <FormControlLabel
                                    className="form-check-label mt-2 roleLabel "
                                    control={<Checkbox disabled={disabled}  checked={field.checked} className="roleCheckbox disabledCheckBox" onChange={handleChange} name={field.name} value={field.value} />}
                                    label={<Typography className="special-text-small text-small" dangerouslySetInnerHTML={{ __html: field.label }} />}
                                  />
                                </FormGroup>
                              </Tooltip>
                              :

                              props.plan.Sub_Level === "10" && field.name === 'role_22' ?
                                <Tooltip classes={classes} placement="top" arrow title="Power Max users have Beta Access to this new role. All other users will have access beginning on May 02." >
                                  <FormGroup  key={index}>
                                    <FormControlLabel
                                      className="form-check-label  mt-2 roleLabel "
                                      control={<Checkbox checked={field.checked}  className="roleCheckbox " onChange={handleChange} name={field.name} value={field.value} />}
                                      label={<Typography className="special-text-small text-small" dangerouslySetInnerHTML={{ __html: field.label }} />}
                                    />
                                  </FormGroup>
                                </Tooltip>
                                :

                                <FormGroup key={index}>
                                  <FormControlLabel
                                    className="form-check-label roleLabel"
                                    control={<Checkbox checked={field.checked} className="roleCheckbox" onChange={handleChange} name={field.name} value={field.value} />}
                                    label={<Typography className="text-small" dangerouslySetInnerHTML={{ __html: field.label }} />}
                                  />
                                </FormGroup>
                          }
                          }*/}
                          <FormGroup key={index}>
                            <FormControlLabel
                              className="form-check-label roleLabel"
                              control={<Checkbox checked={field.checked} className="roleCheckbox" onChange={handleChange} name={field.name} value={field.value} />}
                              label={<Typography className="text-small" dangerouslySetInnerHTML={{ __html: field.label }} />}
                            />
                          </FormGroup>
                        </>

                      );
                    }
                  })
                }
              </div>
              <div className="col-md-6 col-12 p-0">
                <label className="form-check-label  roleTitle head-of-label">GOVT-WIDE ROLES:</label>
                {
                  Fields.map((field, index) => {
                    let spacing = "0px";
                    if (field.name === 'role_3') {
                      spacing = "0.5em";
                    }
                    if (field.name === 'role_1') {
                      //spacing = "1em";
                    }
                    //  let disabled = props.plan.Sub_Level !== "10" && field.name === 'role_18' ? true : false;
                    // if (disabled) {
                    //   field.checked = false;

                    //   field.label = "Deputy Top Appointed Executive  <a target='_blank' href='https://poweralmanac.com/deputy-top-appointed-executive-role/'> <i class=' fa fa-info-circle'> </i></a>   <br/> <span class='text-small'>(e.g. the Assistant City Manager) </span> <span class='new-text-small text-small'>Power Max early access only </span> ";
                    // } 
                    
                    if (index >= 18) {
                      return (
                        <>
                         {/* {
                            props.plan.Sub_Level !== "10" && field.name === 'role_18' ?

                              <Tooltip classes={classes} placement="top" arrow title="Power Max users have early access to this new role. All other users will have access beginning on May 2." >
                                <FormGroup style={{ marginTop: spacing }} key={index}>
                                  <FormControlLabel
                                    className="form-check-label mt-2 roleLabel "
                                    control={<Checkbox disabled={disabled} checked={field.checked} className="roleCheckbox deputyRole disabledCheckBox" onChange={handleChange} name={field.name} value={field.value} />}
                                    label={<Typography className="special-text-small" dangerouslySetInnerHTML={{ __html: field.label }} />}
                                  />
                                </FormGroup>
                              </Tooltip>
                              :

                              props.plan.Sub_Level === "10" && field.name === 'role_18' ?
                                <Tooltip classes={classes} placement="top" arrow title="Power Max users have early access to this new role. All other users will have access beginning on May 2." >
                                  <FormGroup style={{ marginTop: spacing }} key={index}>
                                    <FormControlLabel
                                      className="form-check-label  mt-2 roleLabel "
                                      control={<Checkbox checked={field.checked} className="roleCheckbox deputyRole " onChange={handleChange} name={field.name} value={field.value} />}
                                      label={<Typography className="special-text-small" dangerouslySetInnerHTML={{ __html: field.label }} />}
                                    />
                                  </FormGroup>
                                </Tooltip>
                                :

                                <FormGroup key={index}>
                                  <FormControlLabel
                                    className="form-check-label roleLabel"
                                    control={<Checkbox checked={field.checked} className="roleCheckbox" onChange={handleChange} name={field.name} value={field.value} />}
                                    label={<Typography className="text-small" dangerouslySetInnerHTML={{ __html: field.label }} />}
                                  />
                                </FormGroup>
                          }  */}
                        <FormGroup style={{ marginTop: spacing }} key={index}>
                          <FormControlLabel
                            className="form-check-label  roleLabel"
                            control={<Checkbox checked={field.checked} className="roleCheckbox" onChange={handleChange} name={field.name} value={field.value} />}
                            label={<Typography className="text-small" dangerouslySetInnerHTML={{ __html: field.label }} />}
                          />
                        </FormGroup>
                        </>
                      );
                    }

                  })

                }
              </div>
              <div className="col-12 text-small text-center">
                {CheckedCount === 0 ?
                  <div className="text-danger mb-2 w-100" id="PositionRequired">Select 1 or more roles</div>
                  :
                  ""
                }
                <a onClick={handleSelectAll} href="javascript:void(0)" className="select-all" >Select All</a> | {" "}
                <a onClick={handleDeSelectAll} href="javascript:void(0)" className="deselect-all" >Deselect All</a>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

      </div>
    </div >

  )
}

function mapStateToProps(store, props) {
  return {
    plan: store.client.plan ? store.client.plan : [],
    searchLoading: store.client.searchLoading,
    currentSearch: store.client.currentSearch ? store.client.currentSearch : [],
    appliedFilters: store.client.appliedFilters ? store.client.appliedFilters : [],
    //validFilters: store.client.validFilters,
  }
}

export default connect(mapStateToProps, {})(GovernmentRoleFilter);
