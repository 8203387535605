import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import MyFirstSearchForm from './MyFirstSearchForm'
import SearchInformation from './SearchInformation'

// styles to Stepper
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function MyFirstSearch(props) {
  const classes = useStyles();
  const newSurveyResponse = useSelector((store) => store.client.info.survey_response ? JSON.parse(store.client.info.survey_response) : "");
  const steps = ['Contact Selection Wizard', 'Two Factor Authentication'];
  let FormStep = useSelector((store) => store.client.NewUserServeyFormStep);
  const { toggle, modal } = props;
  const [onWelcome, setOnWelcome] = React.useState(true);
  let activeStep = FormStep.activeStep;
  useEffect(() => {
    if (newSurveyResponse !== "") {
      setOnWelcome(false);
    }
  }, []);
  // get current step template to user
  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <MyFirstSearchForm  welcome={onWelcome} onLoad={setOnWelcome} />;
      case 1:
        return <SearchInformation toggle={toggle} />;
      case 2:
        return <></>;
      default:
        return 'Unknown step';
    }
  }
 
  return (
    <div>
      <Modal centered size={activeStep === 1 ? "md" : "md"} isOpen={modal} toggle={toggle} backdrop={false} className="modal-dialog ">
        {onWelcome ?
          ""
          :
          < ModalHeader >
            {
              steps.map((label, index) => (
                index === activeStep ?
                  label
                  :
                  ""
              ))
            }
          </ModalHeader>
        }
        <ModalBody>
          <div className={classes.root}>
            <div>
              <Typography className={classes.instructions}>
                {getStepContent(activeStep)}
              </Typography>
            </div>
            {/* <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper> */}
          </div>
        </ModalBody>
      </Modal>
    </div >
  );
}

export default MyFirstSearch;