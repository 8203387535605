/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import SaveNewSearch from './SaveNewSearch'
import { setSearchResult, setMySavedSearches, setCurrentSearch, setBestPricing, setSearchLoading } from '../../../reducers/client/actions';
import { getSearchResult, getbestPricing, getMySavedSearches } from '../../../service/client';

// Functional component for loading a existing saved search or download from the search page
function SearchBar(props) {
// constactor used to set current search into loaded state or preset list of savedsearch in search bar
useEffect(() => {
  getMySavedSearches().then(res =>
    updateStateAndStore(res.data.data.savedsearch),
  );
}, []);
  const [modal, setModal] = useState(false);
  const toggle = () => {
   
    setModal(!modal);
    setDialogOpen(false);
    getMySavedSearches().then(res =>
      updateStateAndStore(res.data.data.savedsearch),
    );
  }
  const [mysavedsearches, setMySavedSearchData] = useState([]);
  const [apicallaback, setApiCallBack] = useState(false);

  const dispatch = useDispatch();
  const [showLoadSearch, setShowLoadSearch] = useState(false);
  let [keyword, setKeyword] = useState('');
  let [searchList, setSearchList] = useState([]);

  const [openDialog, setDialogOpen] = React.useState(false);
  const [alertData, setAlertData] = React.useState({});

  // function to open a dialog to save unsaved search result
  const handleDialogClickOpen = (data) => {
    setDialogOpen(true);
    setAlertData(data);
  };
  // function to close a dialog to save unsaved search result
  const handleDialogClose = () => {
    let userSearch = {
      search_params: alertData.search_params,
      id: alertData.id,
      name: alertData.name,
      source: alertData.type,
      first_time_login: "0"
    }
    dispatch(setCurrentSearch(userSearch));
    setDialogOpen(false);
    setShowLoadSearch(false);
    setTimeout(function () {
      handleSearch(userSearch)
    }, 2000);
  };
  
  // funtion to save data in store
  const updateStateAndStore = (data) => {
    dispatch(setMySavedSearches(data));
    setMySavedSearchData(data);
    data.sort(compare);
    setSearchList(data);
  }
  const compare = (a, b) => {
    if (a.id < b.id) return 1;
    if (b.id > a.id) return -1;

    return 0;
  }
  // function to filter existing saved search names by entered keyword match
  const FilterMySavedSearch = (e) => {
    let word = e.target.value;
    setKeyword(word);
    let filteredData = mysavedsearches.filter(searchItem => searchItem.name.toLowerCase().includes(word.toLowerCase()));
    filteredData.sort(compare);
    setSearchList(filteredData);
  }
  //clear loaded saved search item
  const clearKeyWord = () => {
    let word = '';
    setKeyword(word);
    let filteredData = mysavedsearches.filter(searchItem => searchItem.name.toLowerCase().includes(word.toLowerCase()));
    setSearchList(filteredData);
  }
  // common function to load a selected saved search parameter
  const loadSavedSearch = (data) => {
    if (window.location.pathname === "/search" && props.currentSearch.id === "") {
      handleDialogClickOpen(data);
      return false;
    }

    let userSearch = {};
    if (data === null) {
      return false;
    } else {
      userSearch = {
        search_params: data.search_params,
        id: data.id,
        name: data.name,
        source: data.type,
        first_time_login: "0"
      }

    }
    dispatch(setCurrentSearch(userSearch));
    setShowLoadSearch(false);
    setTimeout(function () {
      handleSearch(userSearch)
    }, 2000);

  }
  // function to performing loaded saved search filter criteria
  const handleSearch = (userSearch) => {
    dispatch(setSearchLoading(true));
    getSearchResult(userSearch).then(res => handleSearchResponse(res),);
  }

  // handle saved search responses
  const handleSearchResponse = (response) => {

    dispatch(setSearchLoading(false));
    if (response.data.code === 200) {
      dispatch(setSearchResult(response.data.data));
      let unpaid = 0;
      if (props.plan.Sub_Level !== "10") {
        unpaid = parseInt(response.data.data.lastSearchNumMatched);
      }
      let bestParams = {
        checkout_paid_records: response.data.data.checkout_paid_records,
        checkout_unpaid_records: unpaid,
      }
      if (parseInt(response.data.data.scrvr) !== 0) {
        getbestPricing(bestParams).then(response =>
          dispatch(setBestPricing(response.data.data)),
        )
      } else {
        dispatch(setBestPricing([]));
      }

    }
  }

  // return load saved search template

  return (
    <>
      <section className="my-search dark-grey-bg">
        <div className="container">
          <div className="row">
            <div className="col-md-2 col-4 text-right">
              <ul className="breadcrumb pl-5 yoursearch mt-2">
                <li><strong>Your Search: </strong></li>
              </ul>
            </div>
            <div className="col-md-8 header-right-dropdown-menu" id="SearchBar">
              <div className="row">
                <div className="dropdown w-100">
                  <button className="dropbtn w-100 ">
                    {Object.keys(props.currentSearch).length !== 0 && props.currentSearch.name !== '' ? props.currentSearch.name : <i>save this search to name it</i>}
                    <i className="fa fa-sort-desc float-right" aria-hidden="true"></i>
                  </button>
                  <div className="dropdown-content p-3 w-100">
                    <div className="input-group input-group-sm margin-0 w-100">
                      <p className="mt-1 mb-0 w-100">
                        <strong>Load a Saved Search</strong>
                      </p>
                      <div className="w-100" id="previous-searches ">
                        <div className="input-group input-group-sm margin-0">
                          <input onChange={FilterMySavedSearch} type="text" placeholder="Filter Saved Searched by Name" id="filter-previous-searches" className="form-control" value={keyword} />
                          <div className="input-group-append">
                            <button onClick={clearKeyWord} className="btn btn-secondary clearField" type="button">
                              <i className="fa fa-times-circle"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="results" >
                      <ul className="mt-2 simple-list">
                        {
                          searchList.length > 0 ?
                            searchList.map((savedsearch, index) => {
                              if (index < 20) {
                                return (
                                  <li onClick={() => loadSavedSearch(savedsearch)} key={index} className="simple-list-item py-1">
                                     {savedsearch.name}
                                  </li>
                                );
                              }
                            })
                            :
                            <div className="no-results py-1 text-center text-muted text-small" >
                              <em> - No Searches Found - </em>
                            </div>
                        }
                      </ul>
                    </div>
                  </div>
                </div>

              </div>

            </div>
            <div className="col-md-2 m-auto">
              {props.currentSearch.id === "" && props.searchLoading === false ?
                <Button onClick={toggle} variant="contained" type="button" className="btn save-btn text-white text-uppercase mr-3  save-new-search off-on-search btn-pa">
                  Save this search
                </Button>
                :
                ""
              }
            </div>
          </div>
        </div>
      </section >
      {props.currentSearch.id === "" && props.appliedFilters.IsValidSeach === true ?
        <SaveNewSearch history={props.history} action="save" data={props.searchResult} title="Save New Search" modal={modal} toggle={toggle} />
        :
        ""
      }
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Would you like to save the current search?</DialogTitle>

        <DialogActions>
          <Button variant="contained" onClick={toggle} color="primary" className="btn confirm-save-btn">
            Yes
          </Button>
          <Button variant="contained" onClick={handleDialogClose} color="secondary" className="btn confirm-cancel" autoFocus>
            No Thanks
          </Button>
        </DialogActions>
      </Dialog>

    </>
  );
}

// set initial props to component
function mapStateToProps(store, props) {

  return {
    searchLoading: store.client.searchLoading,
    data: store.client.mySavedSearches ? store.client.mySavedSearches : [],
    plan: store.client.info ? store.client.plan : [],
    searchResult: store.client.searchResult ? store.client.searchResult : [],
    currentSearch: store.client.currentSearch ? store.client.currentSearch : [],
    appliedFilters: store.client.appliedFilters ? store.client.appliedFilters : [],
  }
}

export default withRouter(connect(mapStateToProps, {})(SearchBar));
