// Vendor Imports
import React from "react";
import $ from "jquery";
import { connect } from "react-redux";
import Pagination from '@mui/lab/Pagination';
import { withRouter } from "react-router-dom";
import Swal from 'sweetalert2'
import MaterialTable from "@material-table/core";

// Local Imports
import { createTaskList, assignTask } from "../../../../service/dms";

// create task table list
class CreateTaskTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableDetails: null,
            gov_id: '',
            currentPage: 1,
            confirmSelectedGov: [],
            loading: '',
            createLoading: '',
            columns: [
                {
                    title: "Confirmed",
                    field: "confirmed"
                },
                { title: "Government ID", field: "Government_ID", render: rowData => <span>{rowData.Government_ID}<span className="text-danger">{rowData.notes}</span></span> },
                { title: "Government Name (County)", field: "Government_Place_Name", render: rowData => rowData.Government_Place_Name + "(" + rowData.County_Name + ")" },
                { title: "State", field: "State" },
                { title: "Government Type", field: "Government_Type_Name", render: rowData => rowData.Government_Type_Name + "( " + rowData.Government_Type + ")" },
                { title: "Time Zone", field: "TZ" },
                { title: "# Officials", field: "Government_Place_Name", render: rowData => rowData.Government_Place_Name.toUpperCase() },
            ],
            disablePage: false
        };
    }

    componentDidMount() {
        this.getCreateTaskList();
    }

    // Get create list data
    getCreateTaskList = () => {
        createTaskList().then(res => this.handleResponse(res));
    }

    // response Handler
    handleResponse = res => {
        if (res.data.code === 200) {
            this.setState({
                tableDetails: res.data.data,
                currentPage: res.data.data.currentPage + 1,
                loading: '',
                confirmSelectedGov: [],
                disablePage: false
            });
        } else {
            this.setState({ loading: '', disablePage: false });
            Swal.fire({
                text: res.data.message,
                showCloseButton: true,
                confirmButtonText: "Ok",
                icon: 'error',
                allowOutsideClick: false
            });
        }
    };

    // Gov id change handler
    govIdChange = event => {
        this.setState({ gov_id: event.target.value })
    }

    // Pagination Handler
    paginationChange = (event, page) => {
        this.setState({
            currentPage: page,
            disablePage: true
        });
        createTaskList({ currentPage: page - 1, gov_id: this.state.gov_id }).then(res => this.handleResponse(res));
    }

    // Gov Submit Handler
    handleGovSubmit = () => {
        if (this.state.gov_id.trim() === '') {
            Swal.fire({
                text: 'Please Enter Government ID',
                showCloseButton: true,
                confirmButtonText: "Ok",
                icon: 'info',
                allowOutsideClick: false
            });
            return;
        }
        this.setState({ loading: 'loading' })
        createTaskList({ currentPage: this.state.currentPage, gov_id: this.state.gov_id }).then(res => this.handleSearchResponse(res));
    }

    // Search Response  Handler
    handleSearchResponse = res => {
        if (res.data.code === 200) {
            let searchData = {
                currentPage: res.data.data.currentPage,
                fa: res.data.data.fa,
                pageRecord: res.data.data.pageRecord,
                paginate_count: res.data.data.paginate_count,
                qc: res.data.data.qc,
                task: res.data.data.task.map(t => Object.assign(t, { tableData: { checked: true } })),
                total_count: res.data.data.total_count,
                voiceAgent: res.data.data.voiceAgent
            }
            this.setState({
                tableDetails: searchData,
                currentPage: res.data.data.currentPage + 1,
                loading: '',
                confirmSelectedGov: res.data.data.task,
                disablePage: false
            });
        } else {
            this.setState({ loading: '', disablePage: false });
            Swal.fire({
                text: res.data.message,
                showCloseButton: true,
                confirmButtonText: "Ok",
                icon: 'error',
                allowOutsideClick: false
            });
        }
    }

    // Submit Handler
    handleSubmit = () => {
        if (this.state.confirmSelectedGov.length === 0) {
            Swal.fire({
                text: 'Please Select Government',
                showCloseButton: true,
                confirmButtonText: "Ok",
                icon: 'info',
                allowOutsideClick: false
            });
            return;
        }
        this.setState({ createLoading: 'loading' })
        let params = {
            voiceagent: $("#vaSelect").val(),
            qualitychecker: $("#qcSelect").val(),
            finalapprover: $("#faSelect").val(),
            govts: this.state.confirmSelectedGov.map(c => c.Government_ID),
        }
        assignTask(params).then(res => {
            if (res.data.code === 200) {
                this.setState({ createLoading: '' })
                this.props.history.push("/dms/supervisor/pi-dashboard");
            } else {
                this.setState({ createLoading: '' })
                Swal.fire({
                    text: res.data.message,
                    showCloseButton: true,
                    confirmButtonText: "Ok",
                    icon: 'error',
                    allowOutsideClick: false
                });
            }
        })
    }

    // Get standard govs
    standardGovs = () => {
        this.setState({ gov_id: '' });
        this.getCreateTaskList();
    }

    render() {
        return (
            <div>
                <section className="govt-id mt-3">
                    <h3>Search By Government ID</h3>
                    <div className="input-group">
                        <label>Government ID:</label>
                        <input type="search" className="form-control rounded" placeholder="Search" aria-label="Search"
                            aria-describedby="search-addon" value={this.state.gov_id} onChange={this.govIdChange} />
                        <button type="button" className={"btn btn-outline-primary ml-2 " + this.state.loading} onClick={this.handleGovSubmit}>Search by Government ID</button>
                    </div>
                    <p>OR</p>
                    <h3>Standard Form</h3>
                    <a href="#seeMoreTasks" onClick={this.standardGovs}>CLICK HERE to use Standard Create Task (up to 100 Government IDs) form</a>
                </section>

                {this.state.tableDetails ? <div className="mb-4"><section className="taskform">
                    <div className="row">
                        <div className="col-md-12"><h2>Create Tasks</h2></div>
                    </div>
                    <div className="row">
                        <div className="form-group col-sm-4">
                            <label htmlFor="inputEmail" className="col-form-label">Assign To Voice Agent</label>
                            <select id="vaSelect" className="form-control rounded">
                                {this.state.tableDetails.voiceAgent.map((vca, index) => {
                                    return (
                                        <option key={index} value={vca.emp_id}>{vca.first_name} {vca.last_name}</option>
                                    );
                                })}
                            </select>
                        </div>
                        <div className="form-group col-sm-4">
                            <label htmlFor="inputPassword" className="col-form-label">Assign To Quality Checker</label>
                            <select id="qcSelect" className="form-control rounded">
                                {this.state.tableDetails.qc.map((q, index) => {
                                    return (
                                        <option key={index} value={q.emp_id}>{q.first_name} {q.last_name}</option>
                                    );
                                })}
                            </select>
                        </div>
                        <div className="form-group col-sm-4">
                            <label htmlFor="inputPassword" className="col-form-label">Assign To Final Approver</label>
                            <select id="faSelect" className="form-control rounded">
                                {this.state.tableDetails.fa.map((f, index) => {
                                    return (
                                        <option key={index} value={f.emp_id}>{f.first_name} {f.last_name}</option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12"><p>You can add up to 100 Government IDs at a time OR search by a single Government ID above</p></div>
                    </div>


                    <div className="form-group row">
                        <div className="col-sm-12">
                            <button type="button" className={"create-task-btn " + this.state.createLoading} onClick={this.handleSubmit}>Create Task</button>
                        </div>
                    </div>
                </section>

                    <section className="dmstable" id="seeMoreTasks">
                        <div className={"d-flex justify-content-center mb-2"}>
                            <Pagination
                                count={this.state.tableDetails.paginate_count}
                                onChange={this.paginationChange}
                                showFirstButton={true}
                                showLastButton={true}
                                page={this.state.currentPage}
                                disabled={this.state.disablePage}
                            />
                        </div>
                        <div>
                            <MaterialTable
                                className="table display search-table table-responsive"
                                columns={this.state.columns}
                                data={this.state.tableDetails.task}
                                title=""
                                options={{
                                    sorting: false,
                                    selection: true,
                                    actionsColumnIndex: -1,
                                    paging: false,
                                    emptyRowsWhenPaging: false,
                                    search: false,
                                    toolbar: false
                                }}
                                onSelectionChange={(rows) => this.setState({ confirmSelectedGov: rows })}
                                localization={{
                                    toolbar: {
                                        nRowsSelected: '',
                                    },
                                    body: {
                                        emptyDataSourceMessage: 'Loading ...',
                                    }
                                }}
                            />
                        </div>
                        <div className="d-flex justify-content-center mt-2">
                            <Pagination
                                count={this.state.tableDetails.paginate_count}
                                onChange={this.paginationChange}
                                showFirstButton={true}
                                showLastButton={true}
                                page={this.state.currentPage}
                                disabled={this.state.disablePage}
                            />
                        </div>
                    </section>
                </div> : <div className="text-center">
                        <div
                            className="spinner-border text-primary mt-3"
                            style={{ width: "3rem", height: "3rem", marginLeft: "auto" }}
                            role="status"
                        >

                            <span className="sr-only">Loading...</span>
                        </div></div>
                }

            </div>
        );
    }
}

// Export
export default withRouter(connect()(CreateTaskTable));
