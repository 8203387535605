// Vendor Imports
import React from "react";
import MaterialTable from "@material-table/core";

// Local Imports

// View Billing History Table
class ViewBillingHistoryComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        // Table Columns
        const columns = [
            { title: "ID", field: "billing_id" },
            { title: "User ID", field: "RegUser_ID" },
            { title: "User Email", field: "User_Email" },
            { title: "Transaction Number", field: "transaction_number" },
            { title: "Item Name", field: "item" },
            { title: "Payment Method", field: "payment_method" },
            { title: "Payment Amount", field: "payment_amount" },
            { title: "Date", field: "transaction_date" }
        ];
        return (
            <section className="admin-table" >
                {this.props.tableDetails ?
                    <div>
                        <MaterialTable
                            className="table display search-table table-responsive"
                            columns={columns}
                            data={this.props.tableDetails}
                            title=""
                            options={{
                                sorting: true,
                                actionsColumnIndex: -1,
                                paging: true,
                                pageSize: 20,
                                pageSizeOptions: [20, 50, 100],
                                emptyRowsWhenPaging: false,
                                search: true,
                                toolbar: true
                            }}
                            localization={{
                                toolbar: {
                                    nRowsSelected: '',
                                }
                            }}
                        />
                    </div>
                    : <div className="text-center">
                        <div
                            className="spinner-border text-primary mt-3"
                            style={{ width: "3rem", height: "3rem", marginLeft: "auto" }}
                            role="status"
                        >

                            <span className="sr-only">Loading...</span>
                        </div></div>
                }
            </section>

        );
    }
}

// Export
export default ViewBillingHistoryComponent;
