// Vendor Imports

import React, { useState, useRef } from "react";
import { useSelector } from 'react-redux';
import {  Link } from "react-router-dom";
import Button from '@mui/material/Button';
import { Helmet } from 'react-helmet';
import { reDownloadTotal, reDownloadUpdated } from '../../../service/client';
import AWS from 'aws-sdk';
import s3ParseUrl from 's3-url-parser';

// Confirmation page to download existing total records or update records only
function ConfirmDownload(props) {
  const params = props.computedMatch.params;
  const backBtnRef = useRef();
  const [loading, setLoading] = useState('');
  const currentUser = useSelector((store) => store.client.myaccount);
  const mySavedSearches = useSelector((store) => store.client.mySavedSearches
);
  const savedSearchData = mySavedSearches.filter(res => res.id ==params.id);

  
  //function to call download api
  const handleDownload = (id) => {
    setLoading('loading');
    var element = document.getElementById("body");
    element.classList.add("downloadloading");

    let newArr=[];
    let roleData=[];
    let populationData=[];
    let populationDataMore=[];
    let ftestrTotal=[];
    let criteriastrTotal=[];
    let exptypestrTotal=[];
    let allState=[];
    let govTypeData=[];
    let elecMonth=[];
    let fiscalMnth=[];
    let expQrtSel=[];
    let fteQrt=[];
    let zipcodeData=[];
    let offEmailData=[];
    let newOfficialData=[];
    let refinFilterData=[];
    let ftequartileData=[];
    let finSpendData=[];

    let roleValue=[];
    let roleHeadofReplace='';
    let roleHead_ofReplace='';
    let populationValue='';
    let populationValueData='';
    let allStateValue=[];
    let elecMonthValue=[];
    let fiscalMnthValue='';
    let expQrtSelValue=[];
    let fteQrtValue=[];
    let govTypeDataValue=[];
    let contact_zipcode='';
    let contact_zipcode_readius='';
    let contact_refine_result_filter_include='No';
    let contact_refine_result_filter_exclude='No';

    let contact_FTE_category='';
    let contact_FTE_subcategory='';
    let contact_spend_category='';
    let contact_spend_subcategory='';
    let contact_newoffical_filter='No';
    let contact_recordwithemail_filter='No';

    {savedSearchData[0].search_params.split("&").map((item, index) => {
      if(item.search('role_'))
      {

        if(item.search('populationMoreThan'))
        {

          if(item.search('populationThan'))
          {

            if(item.search('govLoc_byState'))
            {

              if(item.search('FTEQuartileSel'))
              {

                if(item.search('expenseBudgetQuartileSel'))
                {

                  if(item.search('fiscalYearEnd_Month'))
                  {

                    if(item.search('electionMonth'))
                    {

                      if(item.search('govType_'))
                      {

                        if(item.search('totalFTE'))
                        {

                          if(item.search('expType'))
                          {

                            if(item.search('criteria'))
                            {

                              if(item.search('byZipcode'))
                              {
                                contact_zipcode='NA';
                                contact_zipcode_readius='NA';
                                

                                if(item.search('off_email'))
                                {
                                  if(item.search('purchasedOnly'))
                                  {

                                    if(item.search('OnlyHotLeads'))
                                    {
                                      contact_newoffical_filter='No';
                                      

                                      if(item.search('fte'))
                                      {
                                          contact_FTE_category="";
                                          contact_FTE_subcategory="";
                                          
                                          if(item.search('fin'))
                                          {
                                            contact_spend_category="";;
                                            contact_spend_subcategory=""
                                            
                                          } else {
                                            finSpendData=item.split("=");
                                            if(finSpendData[0]=='finPS')
                                            {
                                              contact_spend_category="Public Safety";
                                              contact_spend_subcategory=finSpendData[1];
                                            }

                                            if(finSpendData[0]=='finPW')
                                            {
                                              contact_spend_category="Public Welfare";
                                              contact_spend_subcategory=finSpendData[1];
                                            }

                                            if(finSpendData[0]=='finH')
                                            {
                                              contact_spend_category="Health";
                                              contact_spend_subcategory=finSpendData[1];
                                            }

                                            if(finSpendData[0]=='finU')
                                            {
                                              contact_spend_category="Utilities";
                                              contact_spend_subcategory=finSpendData[1];
                                            }

                                            if(finSpendData[0]=='finT')
                                            {
                                              contact_spend_category="Transportation";
                                              contact_spend_subcategory=finSpendData[1];
                                            }

                                            if(finSpendData[0]=='finL')
                                            {
                                              contact_spend_category="Leisure";
                                              contact_spend_subcategory=finSpendData[1];
                                            }

                                            if(finSpendData[0]=='finF')
                                            {
                                              contact_spend_category="Finance";
                                              contact_spend_subcategory=finSpendData[1];
                                            }

                                            if(finSpendData[0]=='finM')
                                            {
                                              contact_spend_category="Miscellaneous";
                                              contact_spend_subcategory=finSpendData[1];
                                            }
                                          }


                                      } else {
                                          ftequartileData=item.split("=");
                                          if(ftequartileData[0]=='ftePS')
                                          {
                                            contact_FTE_category="Public Safety";
                                            contact_FTE_subcategory=ftequartileData[1];
                                          }

                                          if(ftequartileData[0]=='fteH')
                                          {
                                            contact_FTE_category="Health";
                                            contact_FTE_subcategory=ftequartileData[1];
                                          }

                                          if(ftequartileData[0]=='ftePW')
                                          {
                                            contact_FTE_category="Public Welfare";
                                            contact_FTE_subcategory=ftequartileData[1];
                                          }

                                          if(ftequartileData[0]=='fteU')
                                          {
                                            contact_FTE_category="Utilities";
                                            contact_FTE_subcategory=ftequartileData[1];
                                          }

                                          if(ftequartileData[0]=='fteT')
                                          {
                                            contact_FTE_category="Transport";
                                            contact_FTE_subcategory=ftequartileData[1];
                                          }

                                          if(ftequartileData[0]=='fteL')
                                          {
                                            contact_FTE_category="Leisure";
                                            contact_FTE_subcategory=ftequartileData[1];
                                          }

                                          if(ftequartileData[0]=='fteF')
                                          {
                                            contact_FTE_category="Administration";
                                            contact_FTE_subcategory=ftequartileData[1];
                                          }

                                          if(ftequartileData[0]=='fteM')
                                          {
                                            contact_FTE_category="Miscellanous";
                                            contact_FTE_subcategory=ftequartileData[1];
                                          }

                                      }

                                    } else {
                                      newOfficialData=item.split("=");
                                      if(newOfficialData[0]=='OnlyHotLeads')
                                      {
                                        contact_newoffical_filter='Yes';
                                      } else {
                                        contact_newoffical_filter = 'No';
                                      }
                                    }

                                  } else {
                                    refinFilterData=item.split("=");
                                    if(refinFilterData[0]=='purchasedOnly')
                                    {
                                      contact_refine_result_filter_include = 'Yes';
                                    } else {
                                      contact_refine_result_filter_include = 'No';
                                    }

                                    if(refinFilterData[0]=='NotpurchasedOnly')
                                    {
                                      contact_refine_result_filter_exclude = 'Yes';
                                    } else {
                                      contact_refine_result_filter_exclude = 'No';
                                    }

                                  }


                                } else {
                                  offEmailData=item.split("=");
                                  if(offEmailData[1]=='govtOff_email')
                                  {
                                    contact_recordwithemail_filter = 'Yes';
                                  } else {
                                    contact_recordwithemail_filter = 'No';
                                  }

                                }


                              } else {

                                zipcodeData=item.split("=");
                                if(zipcodeData[0]=='byZipcode')
                                {
                                  contact_zipcode=zipcodeData[1];
                                }
                                if(zipcodeData[0]=='byZipCodeDistance')
                                {
                                  contact_zipcode_readius=zipcodeData[1];
                                }
                              }

                            } else {
                              criteriastrTotal=item.split("=");
                              //console.log('Criteria =',criteriastrTotal[1]);
                            }


                          } else {
                            exptypestrTotal=item.split("=");
                            if(exptypestrTotal[0]==2)
                            {
                              contact_spend_category="Special Categories";
                              contact_spend_subcategory= "Only Capital Expenditures";
                            }
                            if(exptypestrTotal[0]==3)
                            {
                              contact_spend_category="Special Categories";
                              contact_spend_subcategory= "Only Operating Expenditures";
                            }
                            if(exptypestrTotal[0]==4)
                            {
                              contact_spend_category="Special Categories";
                              contact_spend_subcategory= "Only Salaries & Wages";
                            }
                            if(exptypestrTotal[0]==5)
                            {
                              contact_spend_category="Special Categories";
                              contact_spend_subcategory= "Only Expenditures on Suppliers";
                            }
                          }

                        } else {
                          ftestrTotal=item.split("=");
                          //console.log('Total FTE =',ftestrTotal[1]);
                        }

                      } else {
                        govTypeData=item.split("=");
                        //govTypeDataValue+='"'+govTypeData[1].charAt(0).toUpperCase()+ govTypeData[1].slice(1)+'",';
                        govTypeDataValue.push(govTypeData[1].charAt(0).toUpperCase()+ govTypeData[1].slice(1));
                      }

                    } else {
                      elecMonth=item.split("=");
                      if(elecMonth[1]==13)
                      {
                        elecMonth[1]='Not Avlb';
                      }
                      elecMonthValue.push(elecMonth[1]);
                      
                    }

                  } else {
                    fiscalMnth=item.split("=");
                    if(fiscalMnth[1]=='ot+Known')
                    {
                        fiscalMnth[1]='Not Avlb';
                    }
                    //fiscalMnthValue.push(fiscalMnth[1]);
                    fiscalMnthValue+=fiscalMnth[1]+',';
                    
                  }

                } else {
                  expQrtSel=item.split("=");
                  if(expQrtSel[1]==5)
                  {
                    expQrtSel[1]='Others';
                  }
                  expQrtSelValue.push(expQrtSel[1]);
                  
                }

              } else {
                fteQrt=item.split("=");
                if(fteQrt[1]==5)
                {
                  fteQrt[1]='Others';
                }
                fteQrtValue.push(fteQrt[1]);
                
              }


            } else {
              allState=item.split("=");
              allStateValue.push(allState[1].replaceAll('+', " "));
              //='"'+allState[1].replaceAll('+', " ")+'",';
              
            }

          } else {
            populationData=item.split("=");
          }

        } else {
          populationDataMore=item.split("=");
        }
      } else {
        roleData=item.split("=");
        roleHeadofReplace = roleData[1].replaceAll('Headof', "");
        roleHead_ofReplace = roleHeadofReplace.replaceAll('+', " ");
        if(roleHead_ofReplace=='Zoning'){
          roleHead_ofReplace='Planning/Zoning';
        } 
        if(roleHead_ofReplace=='Head of Finance/Budgeting'){
          roleHead_ofReplace='Finance';
        }  
        if(roleHead_ofReplace=='Head of Purchasing/Procurement'){
          roleHead_ofReplace='Purchasing';
        }  
        if(roleHead_ofReplace=='Head of Fire Protection Services'){
          roleHead_ofReplace="Fire";
        }  
        if(roleHead_ofReplace=='Head Building Official'){
          roleHead_ofReplace="Buildings";
        }  
        if(roleHead_ofReplace=='Clerk'){
          roleHead_ofReplace="Clerk's Office";
        }
        roleValue.push(roleHead_ofReplace.replaceAll('Head of ', ""));
      }
      
    })}
    /*let fteQuartileArrayVal=fteQrtValue.substring(0, fteQrtValue.length-1).split(',').sort((a, b) => a-b);
    let spendQuartileArrayVal=expQrtSelValue.substring(0, expQrtSelValue.length-1).split(',').sort((a, b) => a-b);
    const order = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    const sort = (data) =>data.split(',').sort((a, b) => order.indexOf(a) - order.indexOf(b)).join();
    let electionMonthArrayVal=elecMonthValue.substring(0, elecMonthValue.length-1).split(',').sort((a, b) => a-b);*/
    
    const order = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    const sort1 = (data) =>data.split(',').sort((a, b) => order.indexOf(a) - order.indexOf(b)).join();
    const fiscalData=sort1(fiscalMnthValue.substring(0, fiscalMnthValue.length-1));
    
    if (params.type === "total") {
      reDownloadTotal(id).then(res => {
        if (window.analytics) {
            window.analytics.track('Update Records Downloaded', {
              search_name:savedSearchData[0].name,
              search_id:savedSearchData[0].id,
              total: 'yes',
              expire:'no',
              contact_role : roleValue,
              contact_population_min : populationDataMore[1],
              contact_population_max : populationData[1],
              contact_state : allStateValue,
              contact_zipcode : contact_zipcode,
              contact_zipcode_radius : contact_zipcode_readius,
              contact_government_type : govTypeDataValue,
              contact_FTE_quartile : fteQrtValue,
              contact_FTE_category : contact_FTE_category,
              contact_FTE_subcategory : contact_FTE_subcategory,
              contact_spend_quartile : expQrtSelValue,
              contact_spend_category : contact_spend_category,
              contact_spend_subcategory : contact_spend_subcategory,
              contact_fiscal_ending : fiscalData.split(','),
              contact_election_month : elecMonthValue.sort((a, b) => a-b),
              contact_recordwithemail_filter : contact_recordwithemail_filter,
              contact_newoffical_filter : contact_newoffical_filter,
              contact_refine_result_filter_include : contact_refine_result_filter_include,
              contact_refine_result_filter_exclude : contact_refine_result_filter_exclude,
            });
        }
        DownloadFile(res);
      })   
    }
    if (params.type === "updated") {
      reDownloadUpdated(id).then(res => {
        if (window.analytics) {
            window.analytics.track('Update Records Downloaded', {
              search_name:savedSearchData[0].name,
              search_id:savedSearchData[0].id,
              total: 'no',
              expire:'no',
              contact_role : roleValue,
              contact_population_min : populationDataMore[1],
              contact_population_max : populationData[1],
              contact_state : allStateValue,
              contact_zipcode : contact_zipcode,
              contact_zipcode_radius : contact_zipcode_readius,
              contact_government_type : govTypeDataValue,
              contact_FTE_quartile : fteQrtValue,
              contact_FTE_category : contact_FTE_category,
              contact_FTE_subcategory : contact_FTE_subcategory,
              contact_spend_quartile : expQrtSelValue,
              contact_spend_category : contact_spend_category,
              contact_spend_subcategory : contact_spend_subcategory,
              contact_fiscal_ending : fiscalData.split(','),
              contact_election_month : elecMonthValue.sort((a, b) => a-b),
              contact_recordwithemail_filter : contact_recordwithemail_filter,
              contact_newoffical_filter : contact_newoffical_filter,
              contact_refine_result_filter_include : contact_refine_result_filter_include,
              contact_refine_result_filter_exclude : contact_refine_result_filter_exclude,
            });
        }
        DownloadFile(res);
      })
    }
   
  }
  
  // function to download csv file
  const DownloadFile = (response) => {
    /*const AWSS3 = new AWS.S3();
    AWS.config.update({
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
        AWS_SDK_LOAD_CONFIG:1,
    });*/
    const AWSS3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY
    });
    setLoading('');
    var element = document.getElementById("body");
    element.classList.remove("downloadloading");
    if (response.data.code===200) {
      let data = response.data.data;
      const mainURL = data.file;
      const { bucket, key } = s3ParseUrl(mainURL);
      let dwnUrl = AWSS3.getSignedUrl('getObject',
            {Bucket : bucket, Key : key, Expires : 60 * 5}
      );
      let file = dwnUrl;
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = file;
      a.download = data.file_name;
      document.body.appendChild(a);
      a.click();
      backBtnRef.current.click();
    } else {
      alert("Your Download is Not Successful. Please Try Again");
    }
  }
  
    return( 
       <>
          <Helmet>
      <title> Download  - Power Almanac </title>
    </Helmet>
        <section className="my-search">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <ul className="breadcrumb">
                            <li>  <Link to="/my-saved-searches" > My Saved Searches </Link> / Download Records</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        
        <div className="container">
          <div className="col-12">
            <div className="panel panel-primary pt-5">
              <div className="panel-heading account-test text-center">DOWNLOAD CONFIRMATION</div>
              <div className="panel-body background-grey add-subscribe ">
                <div className="row ">
                  <div className="col-sm-12 "> 
                    <div className="col-sm-6 float-left text-left"> 

                    </div>

                    <div className="col-sm-12 float-left "> 
                      {
                        params.type === 'total' ?
                          <>
                            <p> You are about to download a file that includes:  
                              <span className="confirm-download-text"> ALL RECORDS </span> from a search that you have previously downloaded, including records that have NOT been updated.  
                            </p>

                            <p> By clicking the “CONFIRM’ button below, your updated file will download within a few seconds, and this downloaded search will appear on your MY SAVED SEARCHES page with a “Last Downloaded Date” of today and “0 updated” records available.</p>

                            <p> 
                          <i> NOTE:  If you want UPDATED RECORDS ONLY from the previous downloaded search, please click the “GO BACK” button below, and then select the search you’d like to download from the “Records Updated” column. </i>
                            </p> 
                            </>
                          :
                          <>
                            <p>
                            You are about to download a file that includes:  <span className="confirm-download-text"> UPDATED RECORDS ONLY </span> from a search that you have previously downloaded.   Records that have NOT been updated will not be included.</p>

                            <p> By clicking the “CONFIRM’ button below, your updated file will download within a few seconds, and this downloaded search will appear on your MY SAVED SEARCHES page with a “Last Downloaded Date” of today and “0 updated” records available.</p>

                            <p><i>NOTE:  If you want ALL the records from the previous downloaded search (including those NOT updated), please click the “GO BACK” button below, and then select the search you’d like to download from the “Total” column.</i></p>
                        </>
                      }
                      
                      <div className="text-center">
                        <Button onClick={(e) => handleDownload(params.id)} variant="contained" className={"  btn btn-pa button download-btn " + loading}> Confirm 
                          
                        <i className="ml-2 fa fa-download" aria-hidden="true"></i>
                        </Button>
                        <Link ref={backBtnRef} to="/my-saved-searches" className="ml-2 back_btn">
                          <span>
                            <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                          </span>Go Back
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>           
        <div className="downloadmodal">
          <div className="center">
            <div className="content">
              <p id="processmessage" > Please be patient, we are putting together your file now, it may take 30 to 45 seconds. </p>
              <img alt="poweralmanac" src="/images/loading.gif"/> 
              </div>
            </div>
          </div>
       </>
    )
 
}

// Export
export default ConfirmDownload;
