import React from "react";
import { useSelector } from 'react-redux';
import MaterialTable from '@material-table/core';
// SubscriptionList component
function SubscriptionList() {
  const SubscriptionHistory = useSelector((store) => store.client.SubscriptionHistory);
  // customized title column
  const renderDateTitle = (key) => {
    if (key === "startdate") {
      return (
        <span>
          Subscription <br /> Date Start
        </span>
      );
    } else {
      return (
        <span>
          Subscription <br /> Date End
        </span>
      );
    }

  };
  const columns = [
    { title: "ID", field: 'sh_id', defaultSort: "desc" },
    { title: "Name", field: 'Sub_Name' },
    { title: "Description", field: 'notes' },
    { title: renderDateTitle('startdate'), field: 'subscription_started_at' },
    { title: renderDateTitle('enddate'), field: 'subscription_ended_at' },
    { title: "Subscription Status", field: 'status' }
  ];
  const localization = {
    body: {
      emptyDataSourceMessage: 'No matching records found',
    },
    toolbar: {
      searchTooltip: 'Quick Search',
      searchPlaceholder: 'Quick Search'
    }
  };

  return (
    <MaterialTable
      localization={localization}
      className="table display search-table table-responsive"
      id="BillingTable"
      columns={columns}
      data={SubscriptionHistory}
      title=""
      options={{ sorting: true, pageSize: 5, emptyRowsWhenPaging: false }}
    />
  );

}


export default SubscriptionList;
