import React, { useEffect } from "react";
import { BrowserRouter, useLocation } from "react-router-dom";
import AppRoute from "./routes";
import { history } from "./utils";
import withClearCache from "./ClearCache";
import { createTheme, ThemeProvider } from '@mui/material/styles';
const ClearCacheComponent = withClearCache(MainApp);

function App(props) {
  useEffect(() => {
    if (Object.keys(caches).length !== 0) {
      console.log("caches", caches);
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then((names) => {
        //console.log("cache names", names);
        for (const name of names) {
          caches.delete(name);
        }
      });
      // delete browser cache and hard reload
      window.location.reload(true);
    }

  }, []);
  return (
    <ClearCacheComponent />
  );
}

const theme = createTheme();
function MainApp(props) {
  return (
    <BrowserRouter history={history} basename={"/"}>
      <ThemeProvider theme={theme}>
        <AppRoute />
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
