// Vendor Imports
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Swal from 'sweetalert2'
import MaterialTable from "@material-table/core";
// Local Imports
import { getQcDashboard, qcGenerateCsv, getTaskInFaStatus } from "../../../../service/dms";

// QC Dashboard Table
class QcDashboardTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tableDetails: null,
      confirmSelectedTask: [],
      loading: '',
      columns: [
        {
          title: "Actions",
          render: rowData => "Completed"
        },
        { title: "Task#", field: "job_id" },
        { title: "Govt ID", field: "Government_ID" },
        { title: "Govt Name", field: "Government_Place_Name" },
        { title: "Govt Type", field: "Government_Type_Name", render: rowData => rowData.Government_Type_Name + "( " + rowData.Government_Type + ")" },
        { title: "Govt Loc", field: "location" },
        { title: "Time Zone", field: "TZ" },
        { title: "# Ofcls", field: "num_officials" },
        { title: "# Edits (%)", field: "Edits" },
        { title: "Agent", field: "agent" }
      ]
    };
  }

  componentDidMount() {
    // Api call to get data
    getQcDashboard().then(res => this.handleResponse(res));
  }

  // Response Handler
  handleResponse = res => {
    if (res.data.code === 200) {
      this.setState({
        tableDetails: res.data.data
      });
    }
  };

  // Get Edit page link
  getEditLink = val => {
    // let geId = val.ge_ID === null ? "" : val.ge_ID;
    return `/dms/supervisor/qc-edit/${val.Government_ID}/${val.job_id}/${val.category}/${val.ge_ID}`
  }

  // Download CSV Handler
  downloadCsv = params => {
    qcGenerateCsv(params).then(res => {
      if (res.data.code === 200) {
        window.open(res.data.data.path, '_blank');
      } else {
        Swal.fire({
          text: res.data.message,
          showCloseButton: true,
          confirmButtonText: "Ok",
          icon: 'error',
          allowOutsideClick: false
        });
        return;
      }
    })
  }

  render() {
    return (
      <div>
        {this.state.tableDetails ? <div>
          <section className="dmstable mt-3">
            <div className="row voicetitle">
              <div className="col-md-12"><h2>Tasks In Progress</h2>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-bordered ">
                <thead>
                  <tr>
                    <th scope="col">Active Task Status</th>
                    <th scope="col">Total # Governments</th>
                    <th scope="col">% of Total Active</th>

                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Voice Agent</td>
                    <td>{this.state.tableDetails.va_count}</td>
                    <td>{this.state.tableDetails.va_total}%</td>
                  </tr>
                  <tr>
                    <td>Quality Checker</td>
                    <td>{this.state.tableDetails.qc_count}</td>
                    <td>{this.state.tableDetails.qc_total}%</td>
                  </tr>
                  <tr>
                    <td>Final Approver</td>
                    <td>{this.state.tableDetails.fa_count}</td>
                    <td>{this.state.tableDetails.fa_total}%</td>
                  </tr>
                  <tr>
                    <td>Done Updating</td>
                    <td>{this.state.tableDetails.done_count}</td>
                    <td>{this.state.tableDetails.done_total}%</td>
                  </tr>
                  <tr className="mt-1">
                    <th scope="col">Government Database</th>
                    <th scope="col">Total # Governments</th>
                    <th scope="col">% of Total</th>
                  </tr>
                  <tr>
                    <td>Allocated Governments</td>
                    <td>{this.state.tableDetails.allocated_government_total}</td>
                    <td>{this.state.tableDetails.allocated_government_percent}%</td>
                  </tr>
                  <tr>
                    <td>UnAllocated Governments</td>
                    <td>{this.state.tableDetails.unallocated_government_total}</td>
                    <td>{this.state.tableDetails.unallocated_government_percent}%</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>

          <section className="dmstable">
            <div className="row voicetitle">
              <div className="col-md-12"><h2>Tasks in QC Status</h2>
              </div>
            </div>
            <div className="table-responsive">
              <MaterialTable
                className="table display search-table table-responsive"
                columns={
                  [
                    {
                      title: "Actions",
                      render: rowData => <span>
                        <Link to={this.getEditLink(rowData)}>Edit</Link>
                        {rowData.rework !== "" ? <img className="ml-1" src="/images/redflag.png" width="10" height="10" alt="rework" title="rework" /> : ""
                        }
                      </span>
                    },
                    { title: "Task#", field: "job_id" },
                    { title: "Govt ID", field: "Government_ID" },
                    { title: "Govt Name", field: "Government_Place_Name" },
                    { title: "Govt Type", field: "Government_Type_Name", render: rowData => rowData.Government_Type_Name + "( " + rowData.Government_Type + ")" },
                    { title: "Govt Loc", field: "location" },
                    { title: "Time Zone", field: "TZ" },
                    { title: "# Ofcls", field: "num_officials" },
                    { title: "# Edits (%)", field: "Edits" },
                    { title: "Agent", field: "agent" }
                  ]
                }
                data={query =>
                  new Promise((resolve, reject) => {
                    let params = {
                      perPage: query.pageSize,
                      currentPage: query.page + 1,
                      job_status: 'qc'
                    }
                    getTaskInFaStatus(params)
                      .then(response => response)
                      .then(result => {
                        resolve({
                          data: result.data.data.TasksinQCStatus.items,
                          page: result.data.data.TasksinQCStatus.currentPage - 1,
                          totalCount: result.data.data.TasksinQCStatus.total,
                        })
                      })
                  })
                }
                title=""
                options={{
                  sorting: false,
                  selection: false,
                  paging: true,
                  pageSize: 20,
                  pageSizeOptions: [20, 50, 100, { value: 5000, label: 'All' }],
                  emptyRowsWhenPaging: false,
                  search: false,
                  toolbar: false
                }}
                onSelectionChange={(rows) => this.setState({ confirmSelectedTask: rows })}
                localization={{
                  toolbar: {
                    nRowsSelected: '{0} Governments Selected',
                  },
                  body: {
                    emptyDataSourceMessage: '',
                  }
                }}
              />
              {/* <table className="table table-bordered ">
                <thead>
                  <tr>
                    <th scope="col">Actions</th>
                    <th scope="col">Task#</th>
                    <th scope="col">Govt ID</th>
                    <th scope="col">Govt Name</th>
                    <th scope="col">Govt Type</th>
                    <th scope="col">Govt Loc</th>
                    <th scope="col">Time Zone</th>
                    <th scope="col"># Ofcls</th>
                    <th scope="col"># Edits (%)</th>
                    <th scope="col">Agent</th>
                    <th scope="col">DL CSV</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.tableDetails.QcDashboard.TasksinQCStatus.map((listValue, index) => {
                    return (
                      <tr key={index}>
                        <td><Link to={this.getEditLink(listValue)}>Edit</Link>{listValue.rework !== "" ? <img className="ml-1" src="/images/redflag.png" width="10" height="10" alt="rework" title="rework" /> : <></>}</td>
                        <td>{listValue.job_id}</td>
                        <td>{listValue.Government_ID}</td>
                        <td>{listValue.Government_Place_Name}</td>
                        <td>{listValue.Government_Type_Name} ({listValue.Government_Type})</td>
                        <td>{listValue.location}</td>
                        <td>{listValue.TZ}</td>
                        <td>{listValue.num_officials}</td>
                        <td>{listValue.Edits}</td>
                        <td>{listValue.agent}</td>
                        <td><img src="/images/button-download.png" border="0" style={{ cursor: "pointer" }} onClick={() => {
                          this.downloadCsv({
                            gid: listValue.Government_ID,
                            jid: listValue.job_id
                          })
                        }} /></td>
                      </tr>
                    );
                  })
                }
                </tbody>
              </table> */}
            </div>
          </section>
          <section className="dmstable">
            <div className="row voicetitle">
              <div className="col-md-12"><h2>Recently Completed Tasks</h2>
              </div>
            </div>
            <div className="row voicetitle">
              <div className="col-md-12"><h2>Tasks in FA Status</h2>
              </div>
            </div>
            <div className="table-responsive">
              <MaterialTable
                className="table display search-table table-responsive"
                columns={this.state.columns}
                data={query =>
                  new Promise((resolve, reject) => {
                    let params = {
                      perPage: query.pageSize,
                      currentPage: query.page + 1,
                      job_status: 'fa'
                    }
                    getTaskInFaStatus(params)
                      .then(response => response)
                      .then(result => {
                        resolve({
                          data: result.data.data.TasksinFAStatus.items,
                          page: result.data.data.TasksinFAStatus.currentPage - 1,
                          totalCount: result.data.data.TasksinFAStatus.total,
                        })
                      })
                  })
                }
                title=""
                options={{
                  sorting: false,
                  selection: false,
                  paging: true,
                  pageSize: 20,
                  pageSizeOptions: [20, 50, 100, { value: 5000, label: 'All' }],
                  emptyRowsWhenPaging: false,
                  search: false,
                  toolbar: false
                }}
                onSelectionChange={(rows) => this.setState({ confirmSelectedTask: rows })}
                localization={{
                  toolbar: {
                    nRowsSelected: '{0} Governments Selected',
                  },
                  body: {
                    emptyDataSourceMessage: '',
                  }
                }}
              />
              {/* <table className="table table-bordered ">
                <thead>
                  <tr>
                    <th scope="col">Actions</th>
                    <th scope="col">Task#</th>
                    <th scope="col">Govt ID</th>
                    <th scope="col">Govt Name</th>
                    <th scope="col">Govt Type</th>
                    <th scope="col">Govt Loc</th>
                    <th scope="col">Time Zone</th>
                    <th scope="col"># Ofcls</th>
                    <th scope="col"># Edits (%)</th>
                    <th scope="col">Agent</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.tableDetails.QcDashboard.TasksinFAStatus.map((listValue, index) => {
                    return (
                      <tr key={index}>
                        <td>Completed</td>
                        <td>{listValue.job_id}</td>
                        <td>{listValue.Government_ID}</td>
                        <td>{listValue.Government_Place_Name}</td>
                        <td>{listValue.Government_Type_Name} ({listValue.Government_Type})</td>
                        <td>{listValue.location}</td>
                        <td>{listValue.TZ}</td>
                        <td>{listValue.num_officials}</td>
                        <td>{listValue.Edits}</td>
                        <td>{listValue.agent}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table> */}
            </div>
          </section>
        </div> : <div className="text-center">
            <div
              className="spinner-border text-primary mt-3"
              style={{ width: "3rem", height: "3rem", marginLeft: "auto" }}
              role="status"
            >

              <span className="sr-only">Loading...</span>
            </div></div>
        }
      </div>
    );
  }
}

// Export
export default connect()(QcDashboardTable);
