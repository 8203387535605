import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { setAppliedFilters,setValidateFilters } from '../../../../reducers/client/actions';
import { getZipCodes } from '../../../../service/client';
import { loadSavedSearchNameArrayType, getAppliedZipcode, getAppliedRadius } from '../../../../utils'
// location and ZIP code filter component
function LocataionFilter(props) {
  const dispatch = useDispatch();

  let rolesFilter = useSelector(store => store.client.validFilters);
  // location filter and values
  const formFields = [

    { label: "Alabama", checked: true, name: "govLoc_byState[]", value: "Alabama" },
    { label: "Alaska", checked: true, name: "govLoc_byState[]", value: "Alaska" },
    { label: "Arizona", checked: true, name: "govLoc_byState[]", value: "Arizona" },
    { label: "Arkansas", checked: true, name: "govLoc_byState[]", value: "Arkansas" },
    { label: "California", checked: true, name: "govLoc_byState[]", value: "California" },
    { label: "Colorado", checked: true, name: "govLoc_byState[]", value: "Colorado" },
    { label: "Connecticut", checked: true, name: "govLoc_byState[]", value: "Connecticut" },
    { label: "Delaware", checked: true, name: "govLoc_byState[]", value: "Delaware" },
    {
      label: "District Of Columbia", checked: true, name: "govLoc_byState[]", value: "District+Of+Columbia"
    },
    { label: "Florida", checked: true, name: "govLoc_byState[]", value: "Florida" },
    { label: "Georgia", checked: true, name: "govLoc_byState[]", value: "Georgia" },
    { label: "Hawaii", checked: true, name: "govLoc_byState[]", value: "Hawaii" },
    { label: "Idaho", checked: true, name: "govLoc_byState[]", value: "Idaho" },
    { label: "Illinois", checked: true, name: "govLoc_byState[]", value: "Illinois" },
    { label: "Indiana", checked: true, name: "govLoc_byState[]", value: "Indiana" },
    { label: "Iowa", checked: true, name: "govLoc_byState[]", value: "Iowa" },
    { label: "Kansas", checked: true, name: "govLoc_byState[]", value: "Kansas" },
    { label: "Kentucky", checked: true, name: "govLoc_byState[]", value: "Kentucky" },
    { label: "Louisiana", checked: true, name: "govLoc_byState[]", value: "Louisiana" },
    { label: "Maine", checked: true, name: "govLoc_byState[]", value: "Maine" },
    { label: "Maryland", checked: true, name: "govLoc_byState[]", value: "Maryland" },
    { label: "Massachusetts", checked: true, name: "govLoc_byState[]", value: "Massachusetts" },
    { label: "Michigan", checked: true, name: "govLoc_byState[]", value: "Michigan" },
    { label: "Minnesota", checked: true, name: "govLoc_byState[]", value: "Minnesota" },
    { label: "Mississippi", checked: true, name: "govLoc_byState[]", value: "Mississippi" },
    { label: "Missouri", checked: true, name: "govLoc_byState[]", value: "Missouri" },
    { label: "Montana", checked: true, name: "govLoc_byState[]", value: "Montana" },
    { label: "Nebraska", checked: true, name: "govLoc_byState[]", value: "Nebraska" },
    { label: "Nevada", checked: true, name: "govLoc_byState[]", value: "Nevada" },
    { label: "New Hampshire", checked: true, name: "govLoc_byState[]", value: "New+Hampshire" },
    { label: "New Jersey", checked: true, name: "govLoc_byState[]", value: "New+Jersey" },
    { label: "New Mexico", checked: true, name: "govLoc_byState[]", value: "New+Mexico" },
    { label: "New York", checked: true, name: "govLoc_byState[]", value: "New+York" },
    { label: "North Carolina", checked: true, name: "govLoc_byState[]", value: "North+Carolina" },
    {
      label: "North Dakota", checked: true, name: "govLoc_byState[]", value: "North+Dakota"
    },
    { label: "Ohio", checked: true, name: "govLoc_byState[]", value: "Ohio" },
    { label: "Oklahoma", checked: true, name: "govLoc_byState[]", value: "Oklahoma" },
    { label: "Oregon", checked: true, name: "govLoc_byState[]", value: "Oregon" },
    { label: "Pennsylvania", checked: true, name: "govLoc_byState[]", value: "Pennsylvania" },
    { label: "Rhode Island", checked: true, name: "govLoc_byState[]", value: "Rhode+Island" },
    { label: "South Carolina", checked: true, name: "govLoc_byState[]", value: "South+Carolina" },
    { label: "South Dakota", checked: true, name: "govLoc_byState[]", value: "South+Dakota" },
    { label: "Tennessee", checked: true, name: "govLoc_byState[]", value: "Tennessee" },
    { label: "Texas", checked: true, name: "govLoc_byState[]", value: "Texas" },
    { label: "Utah", checked: true, name: "govLoc_byState[]", value: "Utah" },
    { label: "Vermont", checked: true, name: "govLoc_byState[]", value: "Vermont" },
    { label: "Virginia", checked: true, name: "govLoc_byState[]", value: "Virginia" },
    { label: "Washington", checked: true, name: "govLoc_byState[]", value: "Washington" },
    { label: "West Virginia", checked: true, name: "govLoc_byState[]", value: "West+Virginia" },
    { label: "Wisconsin", checked: true, name: "govLoc_byState[]", value: "Wisconsin" },
    { label: "Wyoming", checked: true, name: "govLoc_byState[]", value: "Wyoming" }
  ];
  const [locationexpanded, setLocationExpanded] = React.useState('');
  const [locationByZipexpanded, setLocationZipExpanded] = React.useState('');
  const [zip, setZip] = React.useState(null);
  const [radius, setRadius] = React.useState('');
  const [zipCodes, setZipCodes] = React.useState({ ZipCode: 0, Latitude: 0, Longitude: 0, timezone: 0 });
  const [zipValidate, setZipValidate] = React.useState('');

  let [PreviousParams, setPreviousParams] = React.useState({});

  // set initial location / zip code filter values or loaded saved search parameter
  useEffect(() => {
    if (Object.keys(props.currentSearch).length !== 0 && PreviousParams !== props.currentSearch) {

      let loadParams = "";
      let loadSavedSearch = [];
      let loadedZipCode = "";
      let loadedRadius = "";
      if (props.currentSearch.id !== "0" || props.currentSearch.id !== "") {
        loadParams = props.currentSearch.search_params;
        loadSavedSearch = loadSavedSearchNameArrayType(loadParams, formFields);
        loadedZipCode = getAppliedZipcode(loadParams, ['byZipcode']);
        loadedRadius = getAppliedRadius(loadParams, ['byZipCodeDistance']);


      } else if (props.currentSearch.id === "0" || props.currentSearch.id === "") {
        loadParams = props.currentSearch.params;
        loadSavedSearch = loadSavedSearchNameArrayType(loadParams, formFields);
        loadedZipCode = getAppliedZipcode(loadParams, ['byZipcode']);
        loadedRadius = getAppliedRadius(loadParams, ['byZipCodeDistance']);

      }


      if (Fields !== loadSavedSearch && loadSavedSearch.length !== 0) {
        setPreviousParams(props.currentSearch);
        setCheckedFields(loadSavedSearch.appliedFilters);
        setZip(null);
        setRadius('');
        setCheckedCount(loadSavedSearch.checkedcount);
        if (loadSavedSearch.checkedcount !== 0 && loadSavedSearch.checkedcount !== formFields.length) {
          setLocationExpanded("locationPanel");
          if (locationByZipexpanded === "") {
            setLocationZipExpanded("");
          }
        } else {
          if (locationexpanded === "") {
            setLocationExpanded("");
          }
          if (locationByZipexpanded === "") {
            setLocationZipExpanded("");
          }

        }

      }

      if (loadedZipCode.length !== 0 && loadSavedSearch.checkedcount === 0) {

        setZip(loadedZipCode.zipcode);
        setRadius(loadedRadius.radius);
        if (locationexpanded === "") {
          setLocationExpanded("");
        }
        setLocationZipExpanded("locationZipPanel");

      }

    }
  });


  // set location filter state for opened / closed
  const handleChangeLocationAccordian = (panel) => (event, newExpanded) => {
    setLocationExpanded(newExpanded ? panel : false);
  };
  // set zipcode filter state for opened / closed
  const handleChangeZipAccordian = (panel) => (event, newExpanded) => {
    setLocationZipExpanded(newExpanded ? panel : false);
  };

  const [CheckedCount, setCheckedCount] = React.useState(formFields.length);

  const [Fields, setCheckedFields] = React.useState(formFields);
  // capture location filter changes and validate to call search api
  const handleChange = (event) => {
    let MinimumChecked = 0;
    let uncheckedInputs = [];
    Fields.map((field, index) => {
      if (event.target.value === field.value) {
        field.checked = event.target.checked;
      }
      if (field.checked === true) {
        MinimumChecked++;
      }
      uncheckedInputs.push(field);
    })
    let  allSearchFilter = {};
    if(MinimumChecked===0)
    {
      
      allSearchFilter = {
        rolesf: rolesFilter.rolesf,
        statesf: false,
        gtypef: rolesFilter.gtypef,
        gftef: rolesFilter.gftef,
        gspendf: rolesFilter.gspendf,
        fydf: rolesFilter.fydf,
        molf: rolesFilter.molf
      }
      dispatch(setValidateFilters(allSearchFilter));
    } else {
      allSearchFilter = {
        rolesf: rolesFilter.rolesf,
        statesf: true,
        gtypef: rolesFilter.gtypef,
        gftef: rolesFilter.gftef,
        gspendf: rolesFilter.gspendf,
        fydf: rolesFilter.fydf,
        molf: rolesFilter.molf
      }
      dispatch(setValidateFilters(allSearchFilter));
    }
    setZip(null);
    setRadius('');
    setZipValidate("");
    setCheckedFields(uncheckedInputs);
    setCheckedCount(MinimumChecked);
    let appliedFilters = props.appliedFilters;
    appliedFilters.locations = MinimumChecked;
    appliedFilters.zipcode = "";
    appliedFilters.radius = "";
    setRadius('');
    setZip(null);
    setZipCodes({ ZipCode: 0, Latitude: 0, Longitude: 0, timezone: 0 });
    dispatch(setAppliedFilters(appliedFilters));
    props.triggerSearch(uncheckedInputs, true, allSearchFilter);
  };
  // function to select all location checkboxes
  const handleSelectAll = () => {
    let uncheckedInputs = [];
    Fields.map((field, index) => {
      field.checked = true;
      uncheckedInputs.push(field);
    })
    let  allSearchFilterdt = {
        rolesf: rolesFilter.rolesf,
        statesf: true,
        gtypef: rolesFilter.gtypef,
        gftef: rolesFilter.gftef,
        gspendf: rolesFilter.gspendf,
        fydf: rolesFilter.fydf,
        molf: rolesFilter.molf
    }
    dispatch(setValidateFilters(allSearchFilterdt));
    setCheckedFields(uncheckedInputs);
    setCheckedCount(Fields.length);
    let appliedFilters = props.appliedFilters;
    appliedFilters.locations = 52;
    appliedFilters.zipcode = "";
    appliedFilters.radius = "";
    setRadius('');
    setZip(null);
    setZipCodes({ ZipCode: 0, Latitude: 0, Longitude: 0, timezone: 0 });
    dispatch(setAppliedFilters(appliedFilters));
    props.triggerSearch(uncheckedInputs, true, allSearchFilterdt);
    
  };
  // function to deselect all location checkboxes
  const handleDeSelectAll = () => {
    let uncheckedInputs = [];
    Fields.map((field, index) => {
      field.checked = false;
      uncheckedInputs.push(field);
    })
    let  allSearchFilterdf = {
        rolesf: rolesFilter.rolesf,
        statesf: false,
        gtypef: rolesFilter.gtypef,
        gftef: rolesFilter.gftef,
        gspendf: rolesFilter.gspendf,
        fydf: rolesFilter.fydf,
        molf: rolesFilter.molf
    }
    dispatch(setValidateFilters(allSearchFilterdf));
    setCheckedFields(uncheckedInputs);
    setCheckedCount(0);
    let appliedFilters = props.appliedFilters;
    appliedFilters.locations = 0;
    dispatch(setAppliedFilters(appliedFilters));
  };
  // capture radius filter value and validate zip code value
  const handleRadiusChange = (event) => {
    handleDeSelectAll();
    setZipValidate("");
    setRadius(event.target.value);
    let appliedFilters = props.appliedFilters;
    appliedFilters.radius = event.target.value;
    appliedFilters.zipcode = zip;
    dispatch(setAppliedFilters(appliedFilters));
    if (event.target.value === "" && (zip !== "" || zip !== null)) {
      setZipValidate("Select Radius");
      return false;
    }
    if ((zip === "" || zip === null) && event.target.value !== "") {
      setZipValidate("Select ZIP Code");
      return false;
    }
    if (zip !== "" && zip !== null && event.target.value !== "") {
      getZipCodes(zip).then(res =>
        validateZipAndSearch(res),
      )
    } else {
      //handleSelectAll();
    }

  };

  // function to select zip code from system suggest values and validate before calling search api
  const handleZipCodeSelect = (e) => {
    let value = e.target.value;
    setZip(value);
    handleDeSelectAll();
    let appliedFilters = props.appliedFilters;
    appliedFilters.zipcode = value;
    appliedFilters.radius = radius;
    dispatch(setAppliedFilters(appliedFilters));
    if (value.length >= 2) {
      setZipCodes([]);
      getZipCodes(value).then(res =>
        setZipCodes(res.data.data.zipcode),
      )
    }

    if (value.length === 5 && value.length !== 0) {
      setZipValidate("");
      setZip(value);

      if (value !== null && radius === "") {
        setZipValidate("Select Radius");
        return false;
      }
      if (value === null && radius !== "") {
        setZipValidate("Select ZIP Code");
        return false;
      }
      if (value === "" && value !== null && radius !== "") {
        getZipCodes(value).then(res =>
          validateZipAndSearch(res),
        )

      } else {
        //handleSelectAll();
      }
    } else {
      setZipValidate("Invalid ZIP Code");
    }


  };
  // validate entered zip code is valid or not
  const validateZipAndSearch = (result) => {
    let zipCodes = result.data.data.zipcode;
      if (zipCodes.length !== 0 && zip.length === 5) {
        let  allSearchFilterdzf = {
          rolesf: rolesFilter.rolesf,
          statesf: true,
          gtypef: rolesFilter.gtypef,
          gftef: rolesFilter.gftef,
          gspendf: rolesFilter.gspendf,
          fydf: rolesFilter.fydf,
          molf: rolesFilter.molf
      }
        dispatch(setValidateFilters(allSearchFilterdzf));
        props.triggerSearch('', false,allSearchFilterdzf);
      
    } else {
      setZipValidate("Invalid ZIP Code");
    }
  }


  return (
    <>
      <div id="locationByState" className="row governmentRoles">
        <div className="col">
          <Accordion className="accordianLine" expanded={locationexpanded === 'locationPanel'} onChange={handleChangeLocationAccordian('locationPanel')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="locationPanela-content"
              id="locationPanela-header"
            >
              <Typography className="">Location By State </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="row">
                <div className="col-12 text-small text-center">
                  {CheckedCount === 0 && zip === null && radius === "" ?
                    <div className="text-danger mb-2 w-100" >Select 1 or more states</div>
                    :
                    ""
                  }

                  <a onClick={handleSelectAll} href="javascript:void(0)" className="select-all" >Select All</a> | {" "}
                  <a onClick={handleDeSelectAll} href="javascript:void(0)" className="deselect-all" >Deselect All</a>
                </div>
                {
                  Fields.map((field, index) => {

                    return (
                      <div key={index} className="col-6 pr-0">
                        <FormGroup >
                          <FormControlLabel
                            className="form-check-label roleLabel"
                            control={<Checkbox onChange={handleChange} checked={field.checked} name={field.name} value={field.value} />}
                            label={<Typography className="text-small" dangerouslySetInnerHTML={{ __html: field.label }} />}
                          />
                        </FormGroup>
                      </div>
                    );
                  })
                }
                <div className="col-12 text-small text-center">
                  {CheckedCount === 0 && zip === null && radius === "" ?
                    <div className="text-danger mb-2 w-100" >Select 1 or more states</div>
                    :
                    ""
                  }

                  <a onClick={handleSelectAll} href="javascript:void(0)" className="select-all" >Select All</a> | {" "}
                  <a onClick={handleDeSelectAll} href="javascript:void(0)" className="deselect-all" >Deselect All</a>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>

        </div>

      </div >
      <div className="row">
        <div className="col">
          <Accordion className="accordianLine" expanded={locationByZipexpanded === 'locationZipPanel'} onChange={handleChangeZipAccordian('locationZipPanel')}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="locationZipPanela-header"
            >
              <Typography className="">Location By ZIP Code/Radius </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="w-100">
                <div className="row ">
                  <div className="col-md-6 col-6">
                    <input placeholder="ZIP Code" value={zip !== null ? zip : ""} className="form-control radiusSelect " onChange={handleZipCodeSelect} list="zipcodeList" name="byZipcode" id="searchzipcodeList" />

                    <datalist id="zipcodeList">
                      {
                        zipCodes.length > 0 ?
                          zipCodes.map((value, index) => {
                            return (
                              <option key={index} value={value.ZipCode} />
                            );
                          })
                          :
                          ""
                      }
                    </datalist>
                    {zipValidate !== '' && zipValidate !== "Select Radius" ?

                      <div className="text-danger mb-2 w-100" >{zipValidate}</div>

                      :
                      ""
                    }
                  </div>
                  <div className="col-md-6  col-6">
                    <select value={radius} onChange={(e) => handleRadiusChange(e)} className="form-control radiusSelect " id="radius" name="byZipCodeDistance">
                      <option value="">Radius</option>
                      <option value="1">1 mi</option>
                      <option value="10">10 mi</option>
                      <option value="25">25 mi</option>
                      <option value="50">50 mi</option>
                      <option value="100">100 mi</option>
                      <option value="150">150 mi</option>
                      <option value="200">200 mi</option>
                      <option value="250">250 mi</option>
                    </select>
                    {zipValidate !== '' && zipValidate === "Select Radius" ?

                      <div className="text-danger mb-2 w-100" >{zipValidate}</div>

                      :
                      ""
                    }
                  </div>
                </div>


                <p id="zipfocusHelptxt1">Location by ZIP Code/Radius is an alternative to Location by State, and cannot be combined.</p>
              </Typography>
            </AccordionDetails>
          </Accordion>

        </div>
      </div>
    </>
  )
}

function mapStateToProps(store, props) {
  return {
    currentSearch: store.client.currentSearch ? store.client.currentSearch : [],
    appliedFilters: store.client.appliedFilters ? store.client.appliedFilters : [],
  }
}

export default connect(mapStateToProps, {})(LocataionFilter);