import React, { useState, useEffect } from "react";
import { connect, useDispatch } from 'react-redux';
import { Link, withRouter } from "react-router-dom";
import { Helmet } from 'react-helmet';
import NumberFormat from 'react-number-format';
import MaterialTable from '@material-table/core';
import { setTamMode, setCurrentSearch, setMySavedSearches, setDeleteMySavedSearch } from '../../../reducers/client/actions';
import { getMySavedSearches, updateMySavedSearchName, deleteMySavedSearch } from '../../../service/client';
import { makeStyles } from '@mui/styles';
import Swal from 'sweetalert2'
import Tooltip from '@mui/material/Tooltip';

// My saved search page component
function MySavedSearchComponent(props) {
  let [mysavedsearches, setData] = useState([]);
  const dispatch = useDispatch();
  const [filterby, setFilterby] = useState('all');
  const [isLoading, setIsLoading] = useState(false);

  // get / set my saved search data
  useEffect(() => {
      setIsLoading(true);
      if (window.analytics) {
        window.analytics.page();
      }
      getMySavedSearches().then(res =>
        updateStateAndStore(res.data.data.savedsearch),
      )
      dispatch(setTamMode(false));
  }, []);
  // update redux store and state
  const updateStateAndStore = (data) => {
    dispatch(setMySavedSearches(data));
    setData(data);
    setIsLoading(false);
    let hash = props.history.location.hash;
    if (hash === "#downloads") {
      setFilterby(1);
      FilterMySavedSearch(1, data);
    }
  }

  // filter to only show all or search or saveddownloads
  const FilterMySavedSearch = (filter, data) => {

    setFilterby(filter);
   // props.history.push('/my-saved-searches');
    if (filter !== 'all' && data !== '') {

      let filteredData = data.filter(searchItem => parseInt(searchItem.type) === filter);
      setData(filteredData);
    }
    else if (filter !== 'all' && data === '') {

      let filteredData = props.data.filter(searchItem => parseInt(searchItem.type) === filter);
      //console.log("filterdata", filteredData)
      setData(filteredData);
    } else {

      if (data !== "") {

        setData(data);
      } else {

        setData(props.data);
      }

    }
  };

  // function to load saved search or download parameters and redirect to search page
  const loadSavedSearch = (data, search) => {
    let params = {
      type: data.type,
      name: data.name,
      id: data.id,
      search_params: data.search_params,
      source: data.type,
    }
    dispatch(setCurrentSearch(params));
    if (data.expired && search === false) {
      props.history.push("/confirm-expire-download/" + data.id);
    } else {
      props.history.push("/search");
    }

  }
  const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
      color: '#000',
    },
    tooltip: {
      backgroundColor: '#000',
      fontSize: "1rem"
    },
  }));

  const classes = useStylesBootstrap();
  // customized name column
  const renderName = (row) => {
    return (
      <>
        <Link onClick={(e) => loadSavedSearch(row, true)} >
          {row.name}
        </Link>
        <span className="editIcon ">
          <strong><i style={{ color: "#076599" }} className="ml-2 fa fa-edit float-right text-pa" aria-hidden="true"></i> </strong>
        </span>

      </>
    );
  };
  // customized totoal column
  const renderTotal = (row) => {

    if (row.type === "0") {
      return (
        <Link onClick={(e) => loadSavedSearch(row, false)}>
          <NumberFormat value={row.total} displayType={'text'} thousandSeparator={true} />
          <span className="ml-1 
           +editSearchName">
            records
          </span>
        </Link>
      );
    } else if (row.expired === true) {
      return (
        <Link onClick={(e) => loadSavedSearch(row, false)} >
          <NumberFormat value={row.total} displayType={'text'} thousandSeparator={true} />
          <span className="ml-1 
           +editSearchName">
            records <i style={{ color: "#076599" }} className="ml-2 fa fa-download" aria-hidden="true"></i>
          </span>
        </Link>
      );
    } else {
      return (
        <Link to={"/confirm-download/total/download/" + row.id}>
          <NumberFormat value={row.total} displayType={'text'} thousandSeparator={true} />
          <span className="ml-1 
           +editSearchName">
            records <i style={{ color: "#076599" }} className="ml-2 fa fa-download" aria-hidden="true"></i>
          </span>
        </Link>
      );
    }
  };
  // customized updated column
  const renderUpdated = (row) => {
    if (row.type === "0") {
      return "N/A";
    } else if (row.expired === true) {
      return (
        <span className="text-secondary">
          <NumberFormat value={row.updated} displayType={'text'} thousandSeparator={true} />
          <span className="ml-1 
             +editSearchName">
            updated
          </span>
        </span>
      );
    } else {
      if (row.updated === 0) {
        return "0 updated";
      }
      return (
        <Link to={"/confirm-download/updated/download/" + row.id}>
          <NumberFormat value={row.updated} displayType={'text'} thousandSeparator={true} />
          <span className="ml-1 
             +editSearchName">
            updated  <i style={{ color: "#076599" }} className="ml-2 fa fa-download" aria-hidden="true"></i>
          </span>
        </Link>
      );
    }

  };
  // customized expired column
  const renderExpired = (row) => {
    if (row.type === "0") {
      return "N/A";
    } else if (row.expired === true) {
      return (
        <span className="text-secondary">
          Expired
        </span>
      );
    } else {
      return row.expires;
    }

  };
  // customized name header column
  const renderNameTitle = () => {
    return (
      <span >
        Name
        <sup>

          <Tooltip classes={classes} placement="top" arrow title="Click on the Search NAME to load that search on the Search page.  Edit/Change the name of the search by clicking on the icon" >
            <i className="fa fa-info-circle" aria-hidden="true"></i>
          </Tooltip>

        </sup>
      </span>
    );
  };
  // customized updaed header column
  const renderUpdateTitle = () => {
    return (
      <span>Records UPDATED <sup>
        <Tooltip classes={classes} placement="top" arrow title="An “updated” record is one that has been changed, added or deleted since you last downloaded this file." >
          <i className="fa fa-info-circle" aria-hidden="true"></i>
        </Tooltip>

      </sup><br />Since Last Downloaded
      </span>
    );
  };
  // customized expired header column
  const renderExpireTitle = () => {
    return (
      <span>
        Free Download <br /> Expiration Date
      </span>
    );
  };
  // customized delete header column
  const renderDelete = (row) => {
    return (
      <span className="DeleteIcon">
        <i onClick={() => handleDelete(row)} style={{ color: "#076599" }} className="fa fa-trash" aria-hidden="true"></i>
      </span>
    );
  };

  // function to delete saved search or download
  const handleDelete = (DeleteRow) => {

    Swal.fire({
      html:
        '<div>Are you sure you want to delete <br/> the search named: <b style="color:#044d7f">' + DeleteRow.name + '</b></div>',
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonText: 'Yes, Please delete',
      cancelButtonText: 'Do not delete',
      icon: ''
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        const dataDelete = [...props.data];
        let updatedData = dataDelete.filter(searchItem => searchItem.id !== DeleteRow.id);
        let type = DeleteRow.type ;
        let params = {
          record_id: DeleteRow.id,
          type: type
        }

        deleteMySavedSearch(params).then(res =>
          dispatch(setDeleteMySavedSearch(updatedData)),
          setData(dataDelete),
          setIsLoading(false),
        )
        FilterMySavedSearch(filterby, updatedData);

        return;
      }
    })

  }

  const localization = {
    body: {
      emptyDataSourceMessage: "No Records Found",
      deleteTooltip: 'Delete',
      editTooltip: 'Editer',
      filterRow: {
        filterTooltip: 'Filtrer'
      },
      editRow: {
        deleteText: 'Are you sure you want to delete the search?',
        cancelTooltip: 'Cancel',
        saveTooltip: 'Save'
      }
    },
    header: {
      actions: 'Delete'
    },
    toolbar: {
      searchTooltip: 'Quick Search',
      searchPlaceholder: 'Quick Search'
    }
  };
  // table columns and definitions
  const columns = [
    {
      title: renderNameTitle(),
      headerStyle: { textAlign: 'left' },
      cellStyle: { textAlign: 'left' },
      field: 'name',
      editPlaceholder: "Name",
      customSort: (a, b) => (a.name > b.name) ? 1 : -1,
      render: (rowData) => renderName(rowData),
      validate: rowData => rowData.name === '' ? 'Name cannot be empty' : true,

    },

    {
      title: 'Total',
      field: 'name',
      sorting: true,
      headerStyle: { textAlign: 'right' },
      cellStyle: { textAlign: 'right' },
      customSort: (a,
        b) => a.total - b.total,
      editable: 'never',
      render: (rowData) => renderTotal(rowData)
    },

    {
      field: 'downloaded_date',
      title: renderUpdateTitle(),
      headerStyle: { textAlign: 'right', width: "20%" },
      cellStyle: { textAlign: 'right' },
      sorting: true,
      customSort: (a,
        b) => a.updated - b.updated,
      editable: 'never',
      render: (rowData) => renderUpdated(rowData)
    },

    {
      title: "Last Downloaded",
      field: 'downloaded_date',
      headerStyle: { textAlign: 'right' },
      cellStyle: { textAlign: 'right' },
      defaultSort: "desc",
      editable: 'never',
      render: (rowData) => rowData.type==="0" ? "N/A": rowData.downloaded
    },

    {
      title: renderExpireTitle(),
      field: 'expires',
      headerStyle: { textAlign: 'right' },
      cellStyle: { textAlign: 'right' },
      editable: 'never',
      render: (rowData) => renderExpired(rowData)
    },
    {
      title: "Delete",
      field: '',
      sorting: false,
      headerStyle: {
        textAlign: 'right', width: 65,
        maxWidth: 65,
        minWidth: 20
      },
      cellStyle: {
        textAlign: 'right', width: 20,
        maxWidth: 20,
        minWidth: 20
      },
      editable: 'never',
      render: (rowData) => renderDelete(rowData)
    },


  ];
  // funtion to update saved search name api
  const handleEditNameResponse = (rowData, newValue, response) => {
    setIsLoading(false);
    if (response.data.code === 200) {
      const dataUpdate = [...props.data];
      const index = rowData.tableData.id;
      dataUpdate[index] = rowData;
      let updatedName = [];
      dataUpdate.map((row, index) => {
        if (rowData.id === row.id) {
          row.name = newValue;
        }
        updatedName.push(row);
      });
      setData(updatedName);
      FilterMySavedSearch(filterby, updatedName);
      Swal.fire({
        title: response.data.message,
        text: '',
        icon: 'success',
        showCancelButton: false,
        cancelButtonText: 'Close'
      });
    } else {
      Swal.fire({
        title: response.data.message,
        text: '',
        icon: '',
        showCancelButton: false,
        cancelButtonText: 'Close'
      });

    }

  }
  // function edit and validate search name
  const NameInlineEdit = (newValue, oldValue, rowData, columnDef) => {
    newValue = newValue.replace(/[^a-zA-Z 0-9.]+/g, '');
    if (newValue === '') {
      Swal.fire({
        title: 'Name cannot be empty',
        text: '',
        icon: 'warning',
        showCancelButton: false,
        cancelButtonText: 'Close'
      });
    } else if (newValue !== '' && newValue.length > 40) {
      Swal.fire({
        title: 'Name should be less than 40 characters',
        text: '',
        icon: 'warning',
        showCancelButton: false,
        cancelButtonText: 'Close'
      });
    }
    else {
      let type = rowData.type === '1' ? "downloads" : "search";
      let newname = newValue.replace(/[^a-zA-Z 0-9.]+/g, '');
      let params = {
        new_label: newname,
        record_id: rowData.id,
        type: type
      }
      if (newname === oldValue) {
        return true;
      }
    let exists= mysavedsearches.filter(row=> newname===row.name);
    if(exists.length!==0){
      Swal.fire({
        title: 'Search with this name already exists. Please select a different name to save.',
        text: '',
        icon: 'warning',
        showCancelButton: false,
        cancelButtonText: 'Close'
      });
      return true;
    }
     
      setIsLoading(true);
      updateMySavedSearchName(params).then(updateres =>
        handleEditNameResponse(rowData, newname, updateres),
      )
    }

  }

  // const editable = {
  //   isEditable: rowData => false,
  //   isEditHidden: rowData => true,
  //   isDeletable: rowData => true,
  //   isDeleteHidden: rowData => false,

  //   onRowUpdate: (newData, oldData) =>
  //     new Promise((resolve, reject) => {
  //       setTimeout(() => {
  //         const dataUpdate = [...props.data];
  //         const index = oldData.tableData.id;
  //         dataUpdate[index] = newData;

  //         let type = newData.type == 'download' ? "downloads" : "search";
  //         let params = {
  //           new_label: newData.name,
  //           record_id: newData.id,
  //           type: type
  //         }
  //         setData(dataUpdate);
  //         updateMySavedSearchName(params).then(res =>
  //           dispatch(setUpdatedMySavedSearch(dataUpdate)),
  //         )
  //         FilterMySavedSearch(filterby, dataUpdate);
  //         resolve();
  //       }, 1000);
  //     }),
  //   onRowDelete: oldData =>
  //     new Promise((resolve, reject) => {
  //       setTimeout(() => {
  //         const dataDelete = [...props.data];
  //         const index = oldData.tableData.id;
  //         dataDelete.splice(index, 1);
  //         let type = oldData.type == 'download' ? "downloads" : "search";
  //         let params = {
  //           record_id: oldData.id,
  //           type: type
  //         }

  //         deleteMySavedSearch(params).then(res =>
  //           dispatch(setDeleteMySavedSearch(dataDelete)),
  //           setData(dataDelete),
  //         )

  //         FilterMySavedSearch(filterby, dataDelete);
  //         resolve();
  //       }, 1000);
  //     })
  // };

  return (
    <>
      <Helmet>
        <title> My Saved Searches - Power Almanac </title>
      </Helmet>
      <section className="my-search">
        <div className="container">
          <div className="row">
            <div className="col">
              <ul className="breadcrumb">
                <li>My Saved Searches</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="my-table-container">
        <div className="row my-3">
          <div className="col ">
            <div className="form-check form-check-inline mr-5 all-searches">
              <input checked={filterby === 'all' ? true : false} onClick={(e) => FilterMySavedSearch('all', '')} data="myall" className="form-check-input special-radio-btn" type="radio" name="show" value="" id="showBoth" />
              <label className="form-check-label" htmlFor="showBoth">All Saved Searches</label>
            </div>
            <div className="form-check form-check-inline mr-5 all-searches">

              <input checked={filterby === 0 ? true : false} onClick={(e) => FilterMySavedSearch(0, '')} data="mysearches" className="form-check-input special-radio-btn" type="radio" name="show" value="0" id="showSearch" />
              <label className="form-check-label" htmlFor="showSearch">Only Searches NOT Downloaded</label>
            </div>
            <div className="form-check form-check-inline all-searches">

              <input checked={filterby === 1 ? true : false} onClick={(e) => FilterMySavedSearch(1, '')} data="mydownloads" className="form-check-input special-radio-btn" type="radio" name="show" value="1" id="showDownload" />
              <label className="form-check-label" htmlFor="showDownload">Only DOWNLOADED Searches</label>
            </div>
          </div>
        </div>
        <strong>Updated versions
          <sup>
            <Tooltip classes={classes} placement="top" arrow title="Download the complete file again, including updated records, by clicking on the number of records in the TOTAL column.  Or download only the updated records from a file by clicking on the number in the UPDATED column." >
              <i className="fa fa-info-circle" aria-hidden="true"></i>
            </Tooltip>

          </sup>  of unexpired files can be downloaded FOR FREE.
        </strong>
        <div className="row">
          <div className="col">
            <div className=" mt-3 search-list-table">


              <MaterialTable
                className="table table-responsive table-striped display search-table table-responsive"
                id="resultsTable"
                columns={columns}
                data={mysavedsearches}
                isLoading={isLoading}
                title=""
                localization={localization}
                cellEditable={{
                  onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                    return new Promise((resolve, reject) => {
                      NameInlineEdit(newValue, oldValue, rowData, columnDef);

                      setTimeout(resolve, 1000);
                    });
                  }
                }}

                options={{
                  headerStyle: {
                    whiteSpace: 'nowrap'
                  },
                  actionsColumnIndex: -1, tableLayout: "auto"
                  , sorting: true, paging: true, pageSize: 10, emptyRowsWhenPaging: false
                }}
              />

            </div>
          </div>
        </div>

      </section>

    </>
  );
}

function mapStateToProps(store, props) {
  return {
    data: store.client.mySavedSearches ? store.client.mySavedSearches : []
  }
}

export default withRouter(connect(mapStateToProps, {})(MySavedSearchComponent));
