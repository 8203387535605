import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { loadSavedSearchNameArrayType } from '../../../../utils';
import { setAppliedFilters,setValidateFilters } from '../../../../reducers/client/actions';

// Fiscal year ending filter component
function FiscalYearEnding(props) {
  const [expanded, setExpanded] = React.useState();
  const dispatch = useDispatch();
  const handleChangeAccordian = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  let rolesFilter = useSelector(store => store.client.validFilters);
  // input checkboxes and values
  const formFields = [
    { checked: true, name: "fiscalYearEnd_Month[]", value: "January", label: "January" },
    { checked: true, name: "fiscalYearEnd_Month[]", value: "July", label: "July" },
    { checked: true, name: "fiscalYearEnd_Month[]", value: "February", label: "February" },
    { checked: true, name: "fiscalYearEnd_Month[]", value: "August", label: "August" },
    { checked: true, name: "fiscalYearEnd_Month[]", value: "March", label: "March" },
    { checked: true, name: "fiscalYearEnd_Month[]", value: "September", label: "September" },
    { checked: true, name: "fiscalYearEnd_Month[]", value: "April", label: "April" },
    { checked: true, name: "fiscalYearEnd_Month[]", value: "October", label: "October" },
    { checked: true, name: "fiscalYearEnd_Month[]", value: "May", label: "May" },
    { checked: true, name: "fiscalYearEnd_Month[]", value: "November", label: "November" },
    { checked: true, name: "fiscalYearEnd_Month[]", value: "June", label: "June" },
    { checked: true, name: "fiscalYearEnd_Month[]", value: "December", label: "December" },
    { checked: true, name: "fiscalYearEnd_Month[]", value: "ot+Known", label: "Not Avlb" }
  ];
  const [Fields, setCheckedFields] = React.useState(formFields)
  const [CheckedCount, setCheckedCount] = React.useState(formFields.length);

  let [PreviousParams, setPreviousParams] = React.useState({});
  // set initial checked inputs or loaded saved search parameters
  useEffect(() => {
    if (Object.keys(props.currentSearch).length !== 0 && PreviousParams !== props.currentSearch) {
      let loadParams = "";
      let loadSavedSearch = [];
      if (props.currentSearch.id !== "0" || props.currentSearch.id !== "") {
        loadParams = props.currentSearch.search_params;
        loadSavedSearch = loadSavedSearchNameArrayType(loadParams, formFields);
      } else if (props.currentSearch.id === "0" || props.currentSearch.id === "") {
        loadParams = props.currentSearch.params;
        loadSavedSearch = loadSavedSearchNameArrayType(loadParams, formFields);
      }

      if (Fields !== loadSavedSearch && loadSavedSearch.length !== 0) {
        setPreviousParams(props.currentSearch);
        setCheckedFields(loadSavedSearch.appliedFilters);
        setCheckedCount(loadSavedSearch.checkedcount);
        let appliedFilters = props.appliedFilters;
        appliedFilters.fiscalYearEnding = loadSavedSearch.checkedcount;
        dispatch(setAppliedFilters(appliedFilters));
        if (loadSavedSearch.checkedcount !== formFields.length && loadSavedSearch.checkedcount < 12) {
          setExpanded("fiscalYear");
        } else {
          if (expanded !== "") {

          } else {
            setExpanded("");
          }

        }
      }
    }
  });
  // capture on changed input and trigger search api
  const handleChange = (event) => {
    let MinimumChecked = 0;
    let uncheckedInputs = [];
    Fields.map((field, index) => {
      if (event.target.value === field.value) {
        field.checked = event.target.checked;
      }
      if (field.checked === true) {
        MinimumChecked++;
      }
      uncheckedInputs.push(field);
    })
    let allSearchFilter = {};
    if(MinimumChecked===0)
    {
      allSearchFilter = {
        rolesf: rolesFilter.rolesf,
        statesf: rolesFilter.statesf,
        gtypef: rolesFilter.gtypef,
        gftef: rolesFilter.gftef,
        gspendf: rolesFilter.gspendf,
        fydf: false,
        molf: rolesFilter.molf
      }
      dispatch(setValidateFilters(allSearchFilter));
    } else {
      allSearchFilter = {
        rolesf: rolesFilter.rolesf,
        statesf: rolesFilter.statesf,
        gtypef: rolesFilter.gtypef,
        gftef: rolesFilter.gftef,
        gspendf: rolesFilter.gspendf,
        fydf: true,
        molf: rolesFilter.molf
      }
      dispatch(setValidateFilters(allSearchFilter));
    }
    setCheckedFields(uncheckedInputs);
    setCheckedCount(MinimumChecked);
    let appliedFilters = props.appliedFilters;
    appliedFilters.fiscalYearEnding = MinimumChecked;
    dispatch(setAppliedFilters(appliedFilters));
    props.triggerSearch(uncheckedInputs, true, allSearchFilter);
  };
  // function to select all fiscal checkboxes
  const handleSelectAll = () => {
    let uncheckedInputs = [];
    Fields.map((field, index) => {
      field.checked = true;
      uncheckedInputs.push(field);
    })
    let allSearchFilterdt = {
        rolesf: rolesFilter.rolesf,
        statesf: rolesFilter.statesf,
        gtypef: rolesFilter.gtypef,
        gftef: rolesFilter.gftef,
        gspendf: rolesFilter.gspendf,
        fydf: true,
        molf: rolesFilter.molf
    }
    dispatch(setValidateFilters(allSearchFilterdt));
    setCheckedFields(uncheckedInputs);
    setCheckedCount(Fields.length);
    let appliedFilters = props.appliedFilters;
    appliedFilters.fiscalYearEnding = 13;
    dispatch(setAppliedFilters(appliedFilters));
    props.triggerSearch(uncheckedInputs, true, allSearchFilterdt);
  };
  // function to deselect all fiscal checkboxes
  const handleDeSelectAll = () => {
    let uncheckedInputs = [];
    Fields.map((field, index) => {
      field.checked = false;
      uncheckedInputs.push(field);
    })
    setCheckedFields(uncheckedInputs);
    setCheckedCount(0);
    let appliedFilters = props.appliedFilters;
    appliedFilters.fiscalYearEnding = 0;
    dispatch(setAppliedFilters(appliedFilters));
    let  allSearchFilterdf = {
        rolesf: rolesFilter.rolesf,
        statesf: rolesFilter.statesf,
        gtypef: rolesFilter.gtypef,
        gftef: rolesFilter.gftef,
        gspendf: rolesFilter.gspendf,
        fydf: false,
        molf: rolesFilter.molf
    }
    dispatch(setValidateFilters(allSearchFilterdf));
  };
  let previousParams = "";
  if (Object.keys(props.currentSearch).length !== 0) {
    if (props.currentSearch.id !== "0" && props.currentSearch.id !== "") {
      previousParams = props.currentSearch.search_params;
    } else if (props.currentSearch.id === "0" || props.currentSearch.id === "") {
      previousParams = props.currentSearch.params;

    }
  }

  return (

    <div id="fiscalFilter" className="row governmentRoles">
      <div className="col">
        <Accordion className="accordianLine" expanded={expanded === 'fiscalYear'} onChange={handleChangeAccordian('fiscalYear')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="fiscalYeara-content"
            id="fiscalYeara-header"
          >
            <Typography className="">Fiscal Year Ending </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="row">

              {
                Fields.map((field, index) => {
                  return (
                    <div key={index} className={"col-6 pr-0 month" + index}>
                      <FormGroup >
                        <FormControlLabel
                          className="form-check-label  text-small"
                          control={<Checkbox checked={field.checked} onChange={handleChange} name={field.name} value={field.value} />}
                          label={field.label}
                        />
                      </FormGroup>
                    </div>
                  );
                })
              }
              <div className="col-12 text-small text-center">
                {CheckedCount === 0 ?
                  <div className="text-danger mb-2 w-100" >Select 1 or more months</div>
                  :
                  ""
                }
                <a onClick={handleSelectAll} href="javascript:void(0)" className="select-all" >Select All</a> | {" "}
                <a onClick={handleDeSelectAll} href="javascript:void(0)" className="deselect-all" >Deselect All</a>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

      </div>
    </div >

  )
}

function mapStateToProps(store, props) {
  return {
    currentSearch: store.client.currentSearch ? store.client.currentSearch : [],
    appliedFilters: store.client.appliedFilters ? store.client.appliedFilters : [],
  }
}

export default connect(mapStateToProps, {})(FiscalYearEnding);
